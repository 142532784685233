import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import AuthWrapper from '../../components/Layout/AuthWrapper/AuthWrapper';
import Button from '../../components/UI/Button/Button';
import { PassResetFormTypes } from '../../components/Form/form-types';
import FormInputText from '../../components/Form/Inputs/FormInputText/FormInputText';
import { transformErrors } from '../../services/utils';
import { RouteComponentProps } from 'react-router-dom';
import { passwordReset } from '../../moduls/auth';
import { routesURLs } from '../../Routes';

const initialValues = {
    password: '',
    password_confirmation: '',
};

const attributes = {
    password: {
        placeholder: 'Nové heslo',
        label: null,
        password: true,
    },
    password_confirmation: {
        placeholder: 'Nové heslo znovu',
        label: null,
        password: true,
    },
};

const PasswordReset: React.FC<RouteComponentProps> = (props) => {
    const searchParams = new URLSearchParams(props.location.search);
    const validationSchema = Yup.object().shape({
        // eslint-disable-next-line
        password: Yup.string().min(8, 'Minimální délka je ${min} znaků').required('Povinné pole'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), undefined], 'Hesla se musí shodovat')
            .required('Povinné pole'),
    });

    const onSubmit = async (
        values: Pick<PassResetFormTypes, 'password' | 'password_confirmation'>,
        { resetForm, setErrors }: FormikHelpers<Pick<PassResetFormTypes, 'password' | 'password_confirmation'>>
    ) => {
        if (searchParams.get('email') && searchParams.get('token')) {
            return passwordReset({ ...values, email: searchParams.get('email')!, token: searchParams.get('token')! })
                .then(() => {
                    resetForm();
                    props.history.replace(routesURLs.login);
                })
                .catch((error) => setErrors(transformErrors(error)));
        }
    };
    return (
        <AuthWrapper tagline="Reset hesla">
            <div className="login-content">
                <h1>Reset hesla</h1>
                <p>Zadejte své nové heslo a poté jej raději zopakujte.</p>
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                    {({ isSubmitting }) => (
                        <Form>
                            <FormInputText name="password" attributes={attributes.password} />
                            <FormInputText name="password_confirmation" attributes={attributes.password_confirmation} />
                            <div className="login-controls">
                                <Button type="submit" loading={isSubmitting}>
                                    Změnit heslo
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </AuthWrapper>
    );
};

export default PasswordReset;
