import React from 'react';
import './IconTitle.scss';
import { classes } from '../../../services/utils';

const IconTitle: React.FC<{ icon: string; smallIcon?: boolean; className?: string }> = ({
    icon,
    smallIcon,
    className,
    children,
}) => {
    return (
        <div className={classes('icon-title', { [className || '']: !!className })}>
            <i className={classes(`icon-${icon}`, { 'is-small': !!smallIcon })} />
            <h3>{children}</h3>
        </div>
    );
};

export default IconTitle;
