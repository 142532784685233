import React, { useState, useRef, useEffect } from 'react';
import './FormInputText.scss';
import { useField } from 'formik';
import FormInputWrapper from '../FormInputWrapper/FormInputWrapper';

interface InputAttributes {
    label?: string | null;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    password?: boolean;
    number?: boolean;
    autofocus?: boolean;
    icon?: string;
}

const FormInputText: React.FC<{ attributes: InputAttributes; name: string }> = (props) => {
    const [field, meta] = useField(props);
    const {
        label = null,
        className,
        placeholder,
        disabled,
        password,
        number,
        autofocus,
    }: InputAttributes = props.attributes;

    const [showPassword, setShowPassword] = useState(false);
    const input = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (autofocus && input.current !== null) {
            input.current.focus();
        }
        // eslint-disable-next-line
    }, []);

    // only if NUMBER = true
    const numberControl = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key;
        const specialKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Enter', 'Tab'];
        const reg = /^(\d|e|\+|-|\^|\.|,)/;
        if (specialKeys.every((specialKey) => specialKey !== key) && !reg.test(key)) {
            event.preventDefault();
        }
    };

    return (
        <FormInputWrapper className={className} meta={meta}>
            {label !== null && (
                <label htmlFor={`text-input-${field.name}`} className="form-label">
                    {label || <br />}
                </label>
            )}
            <div className="h-relative">
                <input
                    ref={input}
                    id={`text-input-${field.name}`}
                    className="form-input-text"
                    type={password && !showPassword ? 'password' : 'text'}
                    placeholder={placeholder}
                    disabled={disabled}
                    onKeyDown={(event) => (number ? numberControl(event) : null)}
                    {...field}
                />
                {password && (
                    <button
                        type="button"
                        className={`form-password-show icon-eye${showPassword ? '-crossed' : ''}-gray`}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        Show password
                    </button>
                )}
            </div>
        </FormInputWrapper>
    );
};

export default FormInputText;
