import { Formik, Form, FormikHelpers } from 'formik';
import React from 'react';
import { ProfileEditParameters } from '../../../components/Form/form-types';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import NumberInput from '../../../components/UI/NumberInput/NumberInput';
import gymImage from '../../../assets/images/icons/app/gym-blue.svg';
import homeGymImage from '../../../assets/images/icons/app/home-gym-blue.svg';
import useProfile from '../../../hooks/useProfile';
import { editParameters, editProfile } from '../../../moduls/profile';
import { ProfileSetupValues } from '../../../services/types';
import RadioInput from '../../../components/Form/Inputs/RadioInput/RadioInput';

const ProfileEditParametersModal: React.FC<{
    close: () => void;
    isOpen: boolean;
}> = ({ close, isOpen }) => {
    const { data: profile, mutate } = useProfile();

    if (!profile?.user_questionnaire) return null;

    const initialValues: ProfileEditParameters = {
        weight: profile.user_questionnaire.weight ?? 0,
        place: profile.place ?? 0,
    };

    const onSubmit = async (values: ProfileEditParameters, { setErrors }: FormikHelpers<ProfileEditParameters>) => {
        await editProfile({ name: profile.name, email: profile.email, place: values.place }).catch(() => {});
        return editParameters({
            ...profile?.user_questionnaire,
            ...values,
        }).then(async () => {
            await mutate();
            close();
        });
    };

    return (
        <Modal close={close} isOpen={isOpen}>
            <h3 className="h-mb-2">Změna parametrů účtu</h3>
            <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                {({ values, setFieldValue, isSubmitting }) => (
                    <Form className="h-text-left">
                        <div className="container-row">
                            <div className="h-mb-15">
                                <span className="h-text-bold">Vaše váha</span>
                                <NumberInput
                                    value={values.weight.toString()}
                                    color="pink"
                                    onChange={(value) => setFieldValue('weight', +value)}
                                    unit="kg"
                                    placeholder="Zadejte Vaši váhu"
                                    hideCheckmark
                                />
                            </div>
                            <div className="h-mb-15">
                                <h5 className="h-text-bold">Kde cvičíš?</h5>
                                {setupPlaceOptions.map((option) => (
                                    <RadioInput
                                        key={option.value}
                                        color="blue"
                                        id={option.value.toString()}
                                        image={option.image}
                                        isActive={values.place === +option.value}
                                        value={option.value.toString()}
                                        name="radio"
                                        onChange={(val) => setFieldValue('place', +val)}
                                    >
                                        {option.text}
                                    </RadioInput>
                                ))}
                            </div>
                            <div className="h-text-center h-mt-15">
                                <Button onClick={close} size="link" className="h-mr-15">
                                    Zavřít
                                </Button>
                                <Button type="submit" color="black" className="h-mb-15" loading={isSubmitting}>
                                    Uložit
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ProfileEditParametersModal;

const setupPlaceOptions = [
    {
        image: gymImage,
        text: ProfileSetupValues.FITNESS,
        value: 1,
    },
    {
        image: homeGymImage,
        text: ProfileSetupValues.HOME,
        value: 0,
    },
];
