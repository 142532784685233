import { ProfileEditFormTypes, ProfileEditParameters } from '../components/Form/form-types';
import api from '../services/api';
import { ProfileDataType, UserSetupTypes } from '../services/types';

export const getProfile = () => {
    return api.get('/profile').then((response): ProfileDataType => response.data.data);
};

export const editProfile = (data: ProfileEditFormTypes) => {
    return api.put('/profile', data).then((response) => response.data.data);
};

export const editParameters = (data: ProfileEditParameters) => {
    return api.patch('/user-questionnaire', data).then((response) => response.data.data);
};

export const deleteProfile = () => {
    return api.delete('/profile');
};

export const profileSetup = (data: UserSetupTypes) => {
    return api.post('/user-questionnaire', data);
};
