import React from 'react';
import { DishCoursesType } from '../../../services/types';
import './DayMenu.scss';

const DayMenuHead = () => {
    return (
        <div className="day-menu is-head">
            <div className="day-menu-day"></div>
            <div className="day-menu-item is-head">{DishCoursesType.BREAKFAST}</div>
            <div className="day-menu-item is-head">{DishCoursesType.SNACK_1}</div>
            <div className="day-menu-item is-head">{DishCoursesType.LUNCH}</div>
            <div className="day-menu-item is-head">{DishCoursesType.SNACK_2}</div>
            <div className="day-menu-item is-head">{DishCoursesType.DINNER}</div>
        </div>
    );
};

export default DayMenuHead;
