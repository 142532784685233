import React from 'react';
import RadioInput from '../../../components/Form/Inputs/RadioInput/RadioInput';
import veganImage from '../../../assets/images/icons/app/vegan-purple.svg';
import vegetarianImage from '../../../assets/images/icons/app/vegetarian-purple.svg';
import allFoodImage from '../../../assets/images/icons/app/all-food-purple.svg';
import { ProfileSetupValues } from '../../../services/types';

interface ProfileSetup04PropsType {
    food: string;
    setFood: (value: string) => void;
}

const setup04Options = [
    {
        image: veganImage,
        text: ProfileSetupValues.VEGAN,
        value: 'VEGAN',
    },
    {
        image: vegetarianImage,
        text: ProfileSetupValues.VEGETARIAN,
        value: 'VEGETARIAN',
    },
    {
        image: allFoodImage,
        text: ProfileSetupValues.EAT_ALL,
        value: 'EAT_ALL',
    },
];

const ProfileSetup04: React.FC<ProfileSetup04PropsType> = ({ food, setFood }) => {
    return (
        <>
            <h1>Stravování</h1>
            {setup04Options.map((option) => (
                <RadioInput
                    key={option.value}
                    color="purple"
                    id={option.value}
                    image={option.image}
                    isActive={food === option.value}
                    value={option.value}
                    name="radio"
                    onChange={setFood}
                >
                    {option.text}
                </RadioInput>
            ))}
        </>
    );
};

export default ProfileSetup04;
