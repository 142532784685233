import React from 'react';
import './OverviewList.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as Chevron } from '../../assets/images/icons/ic-chevron-right.svg';
import { classes, createArray } from '../../services/utils';

interface OverviewListPropsType {
    color: 'pink' | 'blue' | 'yellow';
    button: string | JSX.Element;
    route: string;
    items: { name: string; route: string; target?: string }[];
    buttonIcon?: string;
    loading?: boolean;
    buttonChildren?: JSX.Element;
    className?: string;
}

const OverviewList: React.FC<OverviewListPropsType> = ({
    items,
    color,
    button,
    buttonIcon,
    route,
    loading,
    buttonChildren,
    className,
}) => {
    return (
        <div className={classes([`overview-list is-${color}`, className], { 'is-loading': !!loading })}>
            {buttonChildren || (
                <Link to={route} className="overview-list-button">
                    {buttonIcon && <i className={`icon-${buttonIcon}`} />}
                    {button}
                    <Chevron className="overview-list-button-chevron" />
                </Link>
            )}
            <ul className="overview-list-list">
                {!loading
                    ? items.map((item, index) => (
                          <li key={item.name + index}>
                              {item.target ? (
                                  <a
                                      href={item.route}
                                      target={item.target}
                                      rel="noopener noreferrer"
                                      className="overview-list-list-item"
                                  >
                                      {item.name}
                                      <Chevron className="overview-list-chevron" />
                                  </a>
                              ) : (
                                  <Link to={item.route} className="overview-list-list-item">
                                      {item.name}
                                      <Chevron className="overview-list-chevron" />
                                  </Link>
                              )}
                          </li>
                      ))
                    : createArray(2).map((item) => (
                          <li key={item}>
                              <div className="overview-list-list-item">
                                  <div></div>
                                  <Chevron className="overview-list-chevron" />
                              </div>
                          </li>
                      ))}
            </ul>
        </div>
    );
};

export default OverviewList;
