import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import AuthWrapper from '../../components/Layout/AuthWrapper/AuthWrapper';
import Button from '../../components/UI/Button/Button';
// import './Login.scss';
import { PassRecoveryFormTypes } from '../../components/Form/form-types';
import FormInputText from '../../components/Form/Inputs/FormInputText/FormInputText';
import { transformErrors } from '../../services/utils';
import { passwordRecovery } from '../../moduls/auth';
import { RouteComponentProps } from 'react-router-dom';

const initialValues = {
    email: '',
};

const attributes = {
    email: {
        placeholder: 'E-mail',
        label: null,
    },
};

const PasswordRecovery: React.FC<RouteComponentProps> = () => {
    const [done, setDone] = useState(false);
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Nevalidní e-mail').required('Povinné pole'),
    });

    const onSubmit = async (
        values: PassRecoveryFormTypes,
        { resetForm, setErrors }: FormikHelpers<PassRecoveryFormTypes>
    ) => {
        return passwordRecovery(values)
            .then(() => {
                resetForm();
                setDone(true);
            })
            .catch((error) => setErrors(transformErrors(error)));
    };
    return (
        <AuthWrapper tagline="Obnovení hesla">
            <div className="login-content">
                {!done ? (
                    <>
                        <h1>Zapomenuté heslo</h1>
                        <p>Zadejte e-mail, na který Vám pošleme odkaz pro obnovení hesla.</p>
                        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                            {({ isSubmitting }) => (
                                <Form>
                                    <FormInputText name="email" attributes={attributes.email} />
                                    <div className="login-controls">
                                        <Button type="submit" loading={isSubmitting}>
                                            Odeslat
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </>
                ) : (
                    <>
                        <h3>E-mail odeslán</h3>
                        <p className="h-mb-2">Zaslali jsme Vám na e-mail odkaz pro obnovení hesla.</p>
                        <Button onClick={() => setDone(false)}>Opakovat</Button>
                    </>
                )}
            </div>
        </AuthWrapper>
    );
};

export default PasswordRecovery;
