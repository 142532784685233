import React, { useEffect, useState } from 'react';
import './Topbar.scss';
import { routesURLs } from '../../../Routes';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../../assets/images/brands/logo-black-horizontal.png';
import ProfileToggle from './ProfileToggle/ProfileToggle';
import { classes } from '../../../services/utils';
import useViewport from '../../../hooks/useViewport';

import { ReactComponent as HomeIcon } from '../../../assets/images/icons/ic-home.svg';
import { ReactComponent as DumbbellsIcon } from '../../../assets/images/icons/ic-dumbbells.svg';
import { ReactComponent as KnifeForkIcon } from '../../../assets/images/icons/ic-knife-fork.svg';
import useProfile from '../../../hooks/useProfile';

interface Props {
    hideNavigation?: boolean;
}

const Topbar = ({ hideNavigation = false }: Props) => {
    const { isCoach, isTrainingPlan, data: profile } = useProfile();

    const navItems = hideNavigation
        ? []
        : [
              {
                  route: routesURLs.overview,
                  title: 'Nástěnka',
                  mobileTitle: 'Nástěnka',
                  color: 'yellow',
                  icon: HomeIcon,
                  exact: true,
              },
              {
                  route: !profile?.fitness_plan_id
                      ? routesURLs.dashboard + '?modal=no-program'
                      : routesURLs.fitnessPlan,
                  title: 'Tréninkový plán',
                  mobileTitle: 'Trénink',
                  color: 'pink',
                  icon: DumbbellsIcon,
                  exact: false,
                  notActive: !profile?.fitness_plan_id,
              },
              {
                  route: routesURLs.mealPlan,
                  title: 'Stravovací plán',
                  mobileTitle: 'Stravování',
                  color: 'blue',
                  icon: KnifeForkIcon,
                  exact: false,
              },
          ];

    const [isScrolled, setIsScrolled] = useState(false);
    const { breakpoint } = useViewport();

    useEffect(() => {
        function setScrolled() {
            let windowTop = document.documentElement.scrollTop || document.body.scrollTop;
            if (windowTop >= 100 && !isScrolled) {
                setIsScrolled(true);
            } else if (windowTop < 100) {
                setIsScrolled(false);
            }
        }
        window.addEventListener('scroll', setScrolled);
        return () => {
            window.removeEventListener('scroll', setScrolled);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className={classes('topbar', { 'is-scrolled': isScrolled })}>
            <Link to={routesURLs.dashboard} className="topbar-logo">
                <img src={logo} alt="Strong beauty" />
            </Link>
            <div className="topbar-nav-wrapper">
                <ul className="topbar-nav">
                    {navItems
                        .filter((item) => !((isTrainingPlan || isCoach) && item.route === routesURLs.mealPlan))
                        .map((item) => (
                            <li key={item.title}>
                                <NavLink
                                    to={item.route}
                                    activeClassName={item.notActive ? '' : 'is-active'}
                                    className={`topbar-nav-item is-${item.color}`}
                                    title={item.title}
                                    exact={item.exact}
                                >
                                    {breakpoint !== 'large' ? (
                                        <>
                                            <span className="topbar-nav-item-icon">
                                                <item.icon />
                                            </span>
                                            <div>{item.mobileTitle}</div>
                                        </>
                                    ) : (
                                        item.title
                                    )}
                                </NavLink>
                            </li>
                        ))}
                </ul>
            </div>
            <ProfileToggle />
        </div>
    );
};

export default Topbar;
