import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import { getCookie } from '../../services/utils';

const Homepage: React.FC<RouteComponentProps> = () => {
    if (getCookie('jid')) {
        return <Redirect to={routesURLs.dashboard} />;
    } else {
        return <Redirect to={routesURLs.login} />;
    }
};

export default Homepage;
