import React from 'react';
import './RadioInput.scss';
import { classes } from '../../../../services/utils';

interface RadioInputPropsType {
    name: string;
    id: string;
    value: string;
    isActive: boolean;
    onChange: (value: string) => void;
    color: 'yellow' | 'pink' | 'blue' | 'purple';
    image?: string;
    className?: string;
    disabled?: boolean;
}

const RadioInput: React.FC<RadioInputPropsType> = (props) => {
    const { name, id, value, isActive, onChange, image, color, className, disabled, children } = props;
    return (
        <div className={classes('radio-input-wrapper', { [className || '']: !!className })}>
            <label htmlFor={id}>
                <input
                    id={id}
                    type="radio"
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    disabled={disabled}
                />
                <div
                    className={classes(`radio-input is-${color}`, { 'is-active': isActive, 'is-disabled': !!disabled })}
                >
                    <span className="radio-input-radio"></span>
                    {image && (
                        <span className="radio-input-image" style={{ backgroundImage: `url('${image}')` }}></span>
                    )}
                    <div className="radio-input-text">{children}</div>
                </div>
            </label>
        </div>
    );
};

export default RadioInput;
