import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';
import { classes } from '../../services/utils';

export interface ModalPropsType {
    isOpen: boolean;
    close: () => void;
    className?: string;
    hideCloseButton?: boolean;
}

const Modal: React.FC<ModalPropsType> = ({ isOpen, close, className, hideCloseButton, children }) => {
    const backdrop = useRef(null);
    const modal = useRef(null);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }, [isOpen]);

    useEffect(() => {
        function closeModalOnEscape(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                closeModal();
            }
        }
        window.addEventListener('keydown', closeModalOnEscape, false);
        return () => {
            window.removeEventListener('keydown', closeModalOnEscape, false);
        };
        // eslint-disable-next-line
    }, []);

    function closeModal() {
        document.body.style.overflow = 'visible';
        close();
    }

    function closeModalOnBackdropClick(el: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (backdrop.current === el.target) {
            closeModal();
        }
    }

    return ReactDOM.createPortal(
        <div
            ref={backdrop}
            className={classes('modal-backdrop', { 'is-open': isOpen })}
            onClick={closeModalOnBackdropClick}
        >
            <div className="modal" ref={modal}>
                {!hideCloseButton && (
                    <button onClick={closeModal} title="Zavřít modální okno" className="modal-close">
                        Zavřít
                    </button>
                )}
                <div className={classes(['modal-content', className])}>{children}</div>
            </div>
        </div>,
        document.getElementById('modal-root')!,
    );
};

export default Modal;
