import React, { useEffect, useRef, useState } from 'react';
import { FitnessWeek, TrainingColor, updateTraining } from '../../moduls/trainings';
import { ReactComponent as Check } from '../../assets/images/icons/ic-check.svg';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { Identifier } from 'dnd-core';
import { format } from 'date-fns';
import useNotification from '../../hooks/useNotification';
import { Link, useHistory } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import { mutate } from 'swr';

interface DragItem {
    index: [number, number];
    id: string;
    type: string;
}

const FitnessPlanStone: React.FC<{
    id: number;
    index: [number, number];
    done: boolean;
    color: TrainingColor;
    changePosition: (removeIndex: [number, number], addIndex: [number, number]) => void;
    setDraggingId: (id?: number) => void;
    stoneOpacity: 1 | 0;
}> = ({ id, index, color, done, changePosition, setDraggingId, stoneOpacity, children }) => {
    const history = useHistory();
    const { open: openNotification } = useNotification();

    const ref = useRef<HTMLAnchorElement>(null);
    const [isDone, setIsDone] = useState(done);

    useEffect(() => {
        setIsDone(done);
    }, [done]);

    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: 'stone',
        collect(monitor: DropTargetMonitor<DragItem, void>) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // // Don't replace items with themselves
            if (dragIndex[0] === hoverIndex[0] && dragIndex[1] === hoverIndex[1]) {
                return;
            }

            // // Time to actually perform the action
            changePosition(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });

    const [{ opacity }, drag] = useDrag<
        {
            id: number;
            index: [number, number];
        },
        unknown,
        {
            opacity: number;
        }
    >({
        type: 'stone',
        item: () => {
            return { id, index };
        },
        end() {
            setDraggingId(undefined);
        },
        collect: (
            monitor: DragSourceMonitor<
                {
                    id: number;
                    index: [number, number];
                },
                unknown
            >,
        ) => ({
            opacity: monitor.isDragging() ? 0 : 1,
        }),
    });

    useEffect(() => {
        if (!opacity) {
            setDraggingId(id);
        }
    }, [opacity, setDraggingId, id]);

    drag(drop(ref));

    return (
        <Link
            to={routesURLs.trainingDetail.replace(':id', id.toString())}
            ref={ref}
            className={[`fitness-plan-stone is-${color}`, isDone ? 'is-done' : undefined].filter(Boolean).join(' ')}
            data-handler-id={handlerId}
            style={{ opacity: stoneOpacity }}
            onContextMenu={(e) => {
                e.preventDefault();
            }}
        >
            {children}
            <button
                onClick={(e) => {
                    setIsDone(!isDone);
                    updateTraining(id, { done_at: isDone ? null : format(new Date(), 'Y-MM-dd') })
                        .then(async (res) => {
                            mutate('fitness-plan').then((data: FitnessWeek[]) => {
                                if (res.status === 202) {
                                    history.push(routesURLs.fitnessPlan + '?finish-modal=1');
                                }
                            });
                        })
                        .catch(() => {
                            openNotification('Nastala chyba. Opakujte, prosím, akci později.');
                            setIsDone(isDone);
                        });
                    e.preventDefault();
                }}
            >
                <Check />
            </button>
        </Link>
    );
};

export default FitnessPlanStone;
