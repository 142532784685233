import React from 'react';

export const WarningCircle = () => {
    return (
        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3100_2274)">
                <path
                    d="M43.9966 61.4056C59.9637 54.7872 67.5423 36.4781 60.9239 20.511C54.3055 4.54396 35.9964 -3.03465 20.0293 3.58374C4.06228 10.2021 -3.51633 28.5113 3.10205 44.4783C9.72044 60.4454 28.0296 68.024 43.9966 61.4056Z"
                    fill="#FF655F"
                />
                <path
                    d="M32 61.1818C15.1703 61.1818 1.42217 47.9077 0.71106 31.0781V32.5003C0.71106 49.804 14.6962 63.7892 32 63.7892C49.3037 63.7892 63.2888 49.804 63.2888 32.5003C63.2888 32.0263 63.2888 31.5522 63.2888 31.0781C62.5777 47.9077 48.8296 61.1818 32 61.1818Z"
                    fill="#D74E49"
                />
                <path
                    d="M50.7259 48.1442L33.8963 14.722C33.1852 13.0627 30.8148 13.0627 30.1037 14.722L13.274 48.1442C12.5629 49.5664 13.5111 51.4627 15.1703 51.4627H48.5926C50.4889 51.4627 51.437 49.8035 50.7259 48.1442ZM29.6296 27.9961C29.6296 26.5738 30.5777 25.6257 32 25.6257C33.4222 25.6257 34.3703 26.5738 34.3703 27.9961V37.2405C34.3703 38.6627 33.4222 39.6109 32 39.6109C30.5777 39.6109 29.6296 38.6627 29.6296 37.2405V27.9961ZM34.3703 45.0627C34.3703 45.2998 34.3703 45.2998 34.1333 45.5368C34.1333 45.7738 33.8963 45.7738 33.8963 46.0109C33.8963 46.2479 33.6592 46.2479 33.6592 46.2479C33.1852 46.722 32.7111 46.959 32 46.959C31.2889 46.959 30.8148 46.722 30.3407 46.2479C29.8666 45.7738 29.6296 45.2998 29.6296 44.5887C29.6296 43.8776 29.8666 43.4035 30.3407 42.9294C31.2889 41.9813 32.7111 41.9813 33.6592 42.9294C34.1333 43.4035 34.3703 43.8776 34.3703 44.5887V45.0627Z"
                    fill="#D74E49"
                />
                <path
                    d="M50.7259 44.8259L33.8963 11.4036C33.1852 9.74436 30.8148 9.74436 29.8666 11.4036L13.274 44.8259C12.5629 46.2481 13.5111 48.1444 15.1703 48.1444H48.5926C50.4889 48.1444 51.437 46.4851 50.7259 44.8259ZM29.6296 24.6777C29.6296 23.2555 30.5777 22.3073 32 22.3073C33.4222 22.3073 34.3703 23.2555 34.3703 24.6777V33.9222C34.3703 35.3444 33.4222 36.2925 32 36.2925C30.5777 36.2925 29.6296 35.3444 29.6296 33.9222V24.6777ZM34.3703 41.7444C34.3703 41.9814 34.3703 41.9814 34.1333 42.2185C34.1333 42.4555 33.8963 42.4555 33.8963 42.6925C33.8963 42.9296 33.6592 42.9296 33.6592 42.9296C33.1852 43.4036 32.7111 43.6407 32 43.6407C31.2889 43.6407 30.8148 43.4036 30.3407 42.9296C29.8666 42.4555 29.6296 41.9814 29.6296 41.2703C29.6296 40.5592 29.8666 40.0851 30.3407 39.611C31.2889 38.6629 32.7111 38.6629 33.6592 39.611C34.1333 40.0851 34.3703 40.5592 34.3703 41.2703V41.7444Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_3100_2274">
                    <rect width="64" height="64" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
