import React from 'react';
import './FakePasswordInput.scss';
import Button from '../../../components/UI/Button/Button';

const FakePasswordInput: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <div className="form-field-wrapper">
            <label className="form-label">Heslo</label>
            <div className="h-relative">
                <div className="fake-password-input">
                    <span>*******</span>
                    <Button onClick={onClick} size="link">
                        Změnit heslo
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FakePasswordInput;
