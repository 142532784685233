import React from 'react';
import { Link } from 'react-router-dom';
import useProfile from '../../../../hooks/useProfile';
import ProfileAvatar from '../../../../pages/Profile/ProfileAvatar/ProfileAvatar';
import { routesURLs } from '../../../../Routes';
import './ProfileToggle.scss';

const ProfileToggle = () => {
    const { data: profile } = useProfile();

    if (!profile) {
        return null;
    }

    return (
        <Link to={routesURLs.profile} className="profile-toggle">
            <span className="profile-toggle-avatar">
                <ProfileAvatar name={profile.name} photo={profile.profile_photo} fontSize={1} />
            </span>
            Můj účet
        </Link>
    );
};

export default ProfileToggle;
