import React, { useEffect, useRef, useState } from 'react';
import './VideoComponent.scss';
import ReactPlayer from 'react-player';
import { classes, plural } from '../../services/utils';
import { TrainingType } from '../../services/types';
import Tagline from '../UI/Tagline/Tagline';
import useViewport from '../../hooks/useViewport';
import screenfull from 'screenfull';
import CircularProgress from '../UI/CircularProgress/CircularProgress';

interface VideoComponentSimplePropsType {
    open: boolean;
    closeVideo: () => void;
    training: TrainingType;
    color: string;
}

const VideoComponentSimple: React.FC<VideoComponentSimplePropsType> = ({ open, closeVideo, training, color }) => {
    const { breakpoint } = useViewport();
    const [init, setInit] = useState(false);
    const [finished, setFinished] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [played, setPlayed] = useState(0);
    const [seeking, setSeeking] = useState(false);
    const [buffering, setBuffering] = useState(false);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [audioMuted, setAudioMuted] = useState(true);
    const video = useRef<ReactPlayer>(null);

    useEffect(() => {
        function closeVideoModal(event: KeyboardEvent) {
            if (event.key === 'Escape') closeVideo();
        }
        window.addEventListener('keydown', closeVideoModal, false);
        document.addEventListener('fullscreenchange', onFullscreenClose, false);
        return () => {
            document.removeEventListener('fullscreenchange', onFullscreenClose, false);
            window.removeEventListener('keydown', closeVideoModal, false);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setPlaying(false);
        if (open) {
            reset();
        }
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        setVideoPlaying(playing);
        // eslint-disable-next-line
    }, [playing]);

    const exitPlayer = () => {
        closeVideo();
    };

    const reset = (keepOnEnd?: boolean) => {
        setInit(false);
        setFinished(false);
        setPlaying(false);
        if (video.current && !keepOnEnd) video.current.seekTo(0);
    };

    const onEnded = () => {
        setVideoPlaying(false);
        reset(true);
        setFinished(true);
    };

    const onFullscreenClose = () => {
        if (screenfull.isEnabled) {
            screenfull.exit();
        }
    };

    const toggleFullscreen = (open: boolean) => {
        setFullscreen(open);
        if (screenfull.isEnabled) {
            if (open) {
                screenfull.request();
            } else {
                screenfull.exit();
            }
        }
    };

    const togglePlay = () => {
        setPlaying(!playing);
        if (!init) {
            setTimeout(() => {
                setAudioMuted(false);
            }, 250);
        }
        setInit(true);
    };

    const handleSeekMouseDown = () => {
        setSeeking(true);
    };

    const handleSeekChange = (e: any) => {
        setPlayed(parseFloat(e.target.value));
    };

    const handleSeekMouseUp = (e: any) => {
        setSeeking(false);
        if (video.current) video.current.seekTo(parseFloat(e.target.value));
    };

    const handleProgress = (state: {
        played: number;
        playedSeconds: number;
        loaded: number;
        loadedSeconds: number;
    }) => {
        if (!seeking) {
            setPlayed(state.played);
        }
    };

    if (!training.video_url) return null;

    return (
        <div
            className={classes('video-container', {
                'is-open': open,
                'is-fullscreen': fullscreen,
            })}
        >
            <div onClick={exitPlayer} className="video-backdrop"></div>
            <button onClick={exitPlayer} className="video-close">
                Zavřít
            </button>
            <div className={classes(`video-wrapper is-${color}`)}>
                <ReactPlayer
                    className="video"
                    url={training.video_url!}
                    ref={video}
                    muted={audioMuted}
                    controls={false}
                    playing={videoPlaying}
                    width={'100%'}
                    height={'100%'}
                    onEnded={onEnded}
                    onProgress={handleProgress}
                    onBuffer={() => setBuffering(true)}
                    onBufferEnd={() => setBuffering(false)}
                    playsinline
                />
                {init && !finished && (
                    <div className="video-seeker-wrapper">
                        <input
                            type="range"
                            min={0}
                            max={0.999999}
                            className="video-seeker"
                            step="any"
                            value={played}
                            onMouseDown={handleSeekMouseDown}
                            onChange={handleSeekChange}
                            onMouseUp={handleSeekMouseUp}
                            onTouchEnd={handleSeekMouseUp}
                            // onPointerDown={handleSeekMouseDown}
                            // onPointerUp={handleSeekMouseDown}
                        />
                        <div className="video-seeker-track">
                            <span className="video-seeker-track-played" style={{ width: `${played * 100}%` }}></span>
                        </div>
                    </div>
                )}
                {training.duration && (
                    <div className="video-notes-progress">
                        <CircularProgress
                            total={video?.current?.getDuration() || 0}
                            value={
                                Number(
                                    (
                                        (video?.current?.getDuration() ?? 0) - (video?.current?.getCurrentTime() ?? 0)
                                    ).toFixed(0),
                                ) || 0
                            }
                            endText="END"
                        />
                    </div>
                )}
                <div className="video-navigation-controls">
                    <button
                        onClick={() => setAudioMuted(!audioMuted)}
                        className={classes('video-audio-button', {
                            'is-muted': audioMuted,
                        })}
                    >
                        Mute music
                    </button>
                    <button
                        onClick={() => toggleFullscreen(!fullscreen)}
                        className={classes('video-fullscreen-button', {
                            'is-fullscreen': fullscreen,
                        })}
                    >
                        Fullscreen
                    </button>
                </div>
                <div
                    className={classes('video-overlay', {
                        'is-open': !init && open && !finished,
                    })}
                >
                    <h3>V tomhle tréninku tě čeká</h3>
                    <Tagline size="small" className="h-color-medium-gray">
                        celkem {training.exercises.length} {plural(training.exercises.length, 'cvik', 'cviky', 'cviků')}
                    </Tagline>
                    {breakpoint !== 'small' && (
                        <div
                            className="video-overlay-items-wrapper container-row middle-md center-md h-mt-2"
                            style={{ maxWidth: 'none' }}
                        >
                            {training.exercises.map((exercise, index) => {
                                if (index < 6) {
                                    return (
                                        <div key={index} className="col-md-4">
                                            <div
                                                className="video-overlay-item"
                                                title={exercise.name}
                                                style={{
                                                    backgroundImage: `url('${exercise.image}')`,
                                                }}
                                            ></div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    )}
                </div>
                <button
                    onClick={togglePlay}
                    className={classes('video-play-button', {
                        'is-playing': playing,
                    })}
                >
                    Přehrát video
                </button>
                {buffering && (
                    <div className="loader h-py-0">
                        <div className="loader-spinner"></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoComponentSimple;
