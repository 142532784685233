import React, { useEffect, useState } from 'react';
import Topbar from '../Topbar/Topbar';
import Footer from '../Footer/Footer';
import './Dashboard.scss';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { routesURLs, Page, dashboardRoutesList } from '../../../Routes';
import { getCookie } from '../../../services/utils';
import useProfile from '../../../hooks/useProfile';
import Loader from '../../UI/Loader/Loader';
import useSupportbox from '../../../hooks/useSupportbox';
import Modal from '../../Modal/Modal';
import Button from '../../UI/Button/Button';
import Membership from '../../Membership/Membership';
import useFitnessPrograms from '../../../hooks/useFitnessPrograms';

const Dashboard: React.FC = () => {
    const [selectFitnessProgramModal, setSelectFitnessProgramModal] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const { data: profile } = useProfile();
    useSupportbox(profile);

    useEffect(() => {
        if (profile && !profile?.fitness_plan_id && location.pathname.indexOf(routesURLs.fitnessPrograms) === -1) {
            setSelectFitnessProgramModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    useEffect(() => {
        if (location.search.indexOf('modal=no-program') !== -1) {
            setSelectFitnessProgramModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const { error } = useFitnessPrograms();

    const isExpired = error?.response?.status === 402;

    if (isExpired && !location?.pathname?.includes('profil')) {
        return (
            <div className="dashboard">
                <Topbar hideNavigation />
                <div className="dashboard-container">
                    <Membership />
                </div>
                <Footer />
            </div>
        );
    }

    if (!profile) {
        return <Loader />;
    }

    if (!profile?.user_questionnaire) {
        return <Redirect to={routesURLs.profileSetup} />;
    }

    return (
        <div className="dashboard">
            <Topbar hideNavigation={isExpired} />
            <div className="dashboard-container">
                <Switch>
                    {dashboardRoutesList.map((route, key) => (
                        <Route
                            key={key}
                            path={route.path}
                            exact={route.exact || false}
                            render={(props) => {
                                if (!getCookie('jid') || route.hideenForAccess?.includes(profile?.access_category)) {
                                    return <Redirect to={routesURLs.homepage} />;
                                } else {
                                    return <Page {...props} component={route.component} title={route.title} />;
                                }
                            }}
                        />
                    ))}
                    <Redirect to={routesURLs.homepage} />
                </Switch>
            </div>
            {!isExpired && (
                <Modal
                    isOpen={selectFitnessProgramModal}
                    close={() => {
                        setSelectFitnessProgramModal(false);
                        history.replace(location.pathname);
                    }}
                >
                    <div className="h-py-2">
                        <h3 className="h-mb-4">Zatím nemáš vybraný žádný fitness program</h3>
                        <Button
                            to={routesURLs.fitnessPrograms}
                            onClick={() => {
                                setSelectFitnessProgramModal(false);
                            }}
                        >
                            Zvol si ho a začni
                        </Button>
                    </div>
                </Modal>
            )}
            <Footer />
        </div>
    );
};

export default Dashboard;
