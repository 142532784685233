import React from 'react';
import './MealPlanOverview.scss';
import IconTitle from '../../../components/UI/IconTitle/IconTitle';
import { formateNumber } from '../../../services/utils';

interface MealPlanOverviewPropsType {
    nutrients: number;
    proteins: number;
    fats: number;
    carbohydrates: number;
}

const MealPlanOverview: React.FC<MealPlanOverviewPropsType> = ({ nutrients, proteins, fats, carbohydrates }) => {
    return (
        <div className="meal-plan-overview">
            <div className="meal-plan-overview-title">
                <IconTitle icon="knife-fork-black" smallIcon={true}>
                    Živiny
                </IconTitle>
                <div className="meal-plan-overview-title-values">
                    <strong>{formateNumber(nutrients, 0)}</strong> kcal
                </div>
            </div>
            <div className="meal-plan-overview-values">
                <IconTitle icon="meat-pink">
                    {formateNumber(proteins, 0)} g<div className="h-text-size-small">bílkoviny</div>
                </IconTitle>
                <div className="vertical-line"></div>
                <IconTitle icon="fat-blue">
                    {formateNumber(fats, 0)} g<div className="h-text-size-small">tuku</div>
                </IconTitle>
                <div className="vertical-line"></div>
                <IconTitle icon="carbs-yellow">
                    {formateNumber(carbohydrates, 0)} g<div className="h-text-size-small">sacharidů</div>
                </IconTitle>
            </div>
        </div>
    );
};

export default MealPlanOverview;
