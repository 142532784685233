import React from 'react';
import './Tagline.scss';
import { classes } from '../../../services/utils';

const Tagline: React.FC<{ size?: 'small'; className?: string }> = ({ size, className, children }) => {
    return (
        <div className={classes('tagline', { [`is-${size}`]: !!size, [className || '']: !!className })}>{children}</div>
    );
};

export default Tagline;
