import { NotificationStyle } from '../components/UI/Notification/Notification';
import { GlobalTypes, useGlobalState } from '../contexts/global';

const useNotification = () => {
    const { dispatch } = useGlobalState();

    const open = (text: string, style?: NotificationStyle) => {
        dispatch({ type: GlobalTypes.ToggleNotification, text, style });
    };

    const close = () => {
        dispatch({ type: GlobalTypes.ToggleNotification });
    };

    return { open, close };
};

export default useNotification;
