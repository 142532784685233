import React, { useState } from 'react';
import './ProfileSetup.scss';
import AuthWrapper from '../../components/Layout/AuthWrapper/AuthWrapper';
import Pager from './Pager/Pager';
import Button from '../../components/UI/Button/Button';
import ProfileSetupHeightWeight from './ProfileSetupHeightWeight/ProfileSetupHeightWeight';
import ProfileSetupDiet from './ProfileSetupDiet/ProfileSetupDiet';
import { RouteComponentProps } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import { editProfile, profileSetup } from '../../moduls/profile';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { mutate } from 'swr';
import { ProfileDataType } from '../../services/types';
import useNotification from '../../hooks/useNotification';
import ProfileSetupPlace from './ProfileSetupPlace/ProfileSetupPlace';
import useProfile from '../../hooks/useProfile';

export type setupPageColors = 'yellow' | 'pink' | 'blue' | 'purple';

interface ProfileSetupControlsPropsType {
    color: setupPageColors;
    activeIndex: number;
    disable: boolean;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    next: () => void;
    loading: boolean;
}

const ProfileSetup: React.FC<RouteComponentProps> = (props) => {
    const profile = useProfile();

    const { state, dispatch } = useGlobalState();
    const [activeIndex, setActiveIndex] = useState(0);

    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [place, setPlace] = useState('');
    const [diet_preference, setDietPreference] = useState('');

    const { open: openNotification } = useNotification();

    const setupPages: {
        color: setupPageColors;
        coach: 'katy' | 'domi' | 'marti' | 'reni';
        component: JSX.Element;
    }[] = [
        {
            color: 'yellow',
            coach: 'katy',
            component: (
                <ProfileSetupHeightWeight height={height} setHeight={setHeight} weight={weight} setWeight={setWeight} />
            ),
        },
        {
            color: 'blue',
            coach: 'marti',
            component: <ProfileSetupPlace place={place} setPlace={setPlace} />,
        },
        {
            color: 'purple',
            coach: 'reni',
            component: <ProfileSetupDiet food={diet_preference} setFood={setDietPreference} />,
        },
    ];

    function nextIsDisabled(): boolean {
        switch (activeIndex) {
            case 0:
                return !height || !weight || +height % 1 !== 0 || +weight % 1 !== 0;
            case 1:
                return !place;
            case 2:
                return !diet_preference;
        }
        return true;
    }

    async function next() {
        if (activeIndex < setupPages.length - 1) {
            setActiveIndex(activeIndex + 1);
        } else {
            dispatch({ type: GlobalTypes.ToggleLoader, loader: 'profileSetup' });
            await editProfile({ email: profile.data!.email, name: profile.data!.name, place: +place as 0 | 1 }).catch(
                (error) => {
                    openNotification(error.response.data.message);
                },
            );
            profileSetup({ height: +height, weight: +weight, diet_preference, place: +place as 0 | 1 })
                .then(() => {
                    mutate(
                        'profile',
                        (profile: ProfileDataType) => ({
                            ...profile,
                            place: +place,
                            user_questionnaire: {
                                diet_preference,
                                height: +height,
                                weight: +weight,
                                place,
                            },
                        }),
                        false,
                    );
                    props.history.push(routesURLs.dashboard);
                })
                .catch((error) => {
                    openNotification(error.response.data.message);
                })
                .finally(() => dispatch({ type: GlobalTypes.ToggleLoader }));
        }
    }

    return (
        <AuthWrapper
            tagline="Nastavení profilu"
            color={setupPages[activeIndex].color}
            coach={setupPages[activeIndex].coach}
            className="profile-setup"
            controls={
                <ProfileSetupControls
                    color={setupPages[activeIndex].color}
                    activeIndex={activeIndex}
                    disable={nextIsDisabled()}
                    setActiveIndex={setActiveIndex}
                    next={next}
                    loading={state.loader === 'profileSetup'}
                />
            }
        >
            <Pager activeIndex={activeIndex} items={setupPages} />
            {setupPages[activeIndex].component}
        </AuthWrapper>
    );
};

const ProfileSetupControls: React.FC<ProfileSetupControlsPropsType> = ({
    color,
    activeIndex,
    disable,
    setActiveIndex,
    next,
    loading,
}) => (
    <div className="profile-setup-controls">
        {activeIndex > 0 && (
            <Button color="gray" onClick={() => setActiveIndex(activeIndex - 1)}>
                Zpět
            </Button>
        )}
        <Button color={color} onClick={next} disabled={disable} loading={loading}>
            Potvrdit
        </Button>
    </div>
);

export default ProfileSetup;
