import api from '../services/api';
import { SubscriptionDataType } from '../services/types';

export const getSubscriptions = () => {
    return api.get('/subscriptions').then((res) => res.data.data);
};

export const cancelSubscriptions = (id: number) => {
    return api.delete(`/subscriptions/${id}`).then((res) => res.data.data);
};

export const getSubscriptionsData = () => {
    return api.get('/subscriptions/billing-address').then((res) => res.data.data);
};

export const editSubscriptionsData = (data: Omit<SubscriptionDataType, 'countries'>) => {
    const finalData = { ...data };
    for (const key in finalData) {
        if (Object.prototype.hasOwnProperty.call(finalData, key)) {
            const element = finalData[key as keyof Omit<SubscriptionDataType, 'countries'>];
            finalData[key as keyof Omit<SubscriptionDataType, 'countries'>] = element === '' ? null : element;
        }
    }
    return api.put('/subscriptions/billing-address', finalData).then((res) => res.data.data);
};
