import React from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';

interface CarouselPropsType {
    settings: Settings;
}

const Carousel: React.FC<CarouselPropsType> = ({ children, settings }) => {
    return <Slider {...settings}>{children}</Slider>;
};

export default Carousel;
