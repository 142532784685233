import useSWR, { cache } from 'swr';
import { getProfile } from '../moduls/profile';
import { ProfileDataType } from '../services/types';

const useProfile = () => {
    const data = useSWR<ProfileDataType>('profile', getProfile, {
        revalidateOnMount: !cache.has('profile'),
    });
    return {
        ...data,
        isCoach: data.data?.access_category === 'COACHING',
        isTrainingPlan: data.data?.access_category === 'TRAINING_PLAN',
    };
};

export default useProfile;
