import useSWR from 'swr';
import { getFitnessPlan } from '../moduls/trainings';
import { FitnessProgram } from './useFitnessPrograms';

export type FitnessPlan = {
    day: null | number;
    done_at: null | string;
    fitness_program: FitnessProgram;
    id: number;
    instructions: string;
    pdf: string | null;
};

const useFitnessPlan = (id?: number) => {
    const data = useSWR<FitnessPlan>(['fitness-plan', id], id ? getFitnessPlan : null, {
        errorRetryCount: 0,
    });
    return data;
};

export default useFitnessPlan;
