import React from 'react';
import { Link } from 'react-router-dom';
import { routesURLs } from '../../../Routes';
import { DishCoursesType, MealWeekRecipeType } from '../../../services/types';
import { classes } from '../../../services/utils';
import './RecipeItem.scss';

const RecipeItem: React.FC<{
    onClick: (id: number) => void;
    recipe: MealWeekRecipeType;
    loading: boolean;
    favorite: boolean;
}> = ({ onClick, recipe, loading, favorite }) => {
    return (
        <div className={classes('recipe-item', { 'is-favorite': favorite })}>
            <Link
                to={{ pathname: routesURLs.mealPlanMenu + recipe.id, search: `type=${recipe.type}` }}
                onClick={() => {
                    document.body.style.overflow = 'visible';
                }}
                className="recipe-item-image"
                style={{ backgroundImage: `url('${recipe.image}')` }}
            ></Link>
            <div className="recipe-item-content">
                <div className="h-color-medium-gray h-text-size-small h-text-bold h-mb-025">
                    {Object.values(DishCoursesType)[recipe.type]}
                </div>
                <h5>
                    <Link
                        to={{ pathname: routesURLs.mealPlanMenu + recipe.id, search: `type=${recipe.type}` }}
                        onClick={() => {
                            document.body.style.overflow = 'visible';
                        }}
                    >
                        {recipe.name}
                    </Link>
                </h5>
            </div>
            <button
                onClick={() => (loading ? {} : onClick(recipe.id))}
                className="recipe-item-button"
                disabled={loading}
                title="Vybrat recept"
            >
                Vybrat recept
            </button>
        </div>
    );
};

export default RecipeItem;
