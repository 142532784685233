import React, { useEffect, useState } from 'react';
import { pad } from '../../../services/utils';
import CircularProgress from '../../UI/CircularProgress/CircularProgress';
import './ExerciseBreak.scss';
import { longBeep, shortBeep } from '../../../constants/audio';

interface ExerciseBreakPropsType {
    length: number;
    onEnded: () => void;
    playing: boolean;
}

const countDown = 10;

const ExerciseBreak: React.FC<ExerciseBreakPropsType> = ({ length, onEnded, playing }) => {
    const [exersiceBreak, setExerciseBreak] = useState(length + 1);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        let timeout: NodeJS.Timeout;
        if (playing) {
            setTimeout(() => {
                setExerciseBreak((num) => num - 1);
            }, 1);
            interval = setInterval(() => {
                let number = 999999;
                setExerciseBreak((num) => {
                    number = num;
                    return num - 1;
                });
                if (number <= countDown + 1 && number > 1) {
                    shortBeep.currentTime = 0;
                    shortBeep.play();
                } else if (number === 1) {
                    longBeep.currentTime = 0;
                    longBeep.play();
                }
            }, 1000);

            timeout = setTimeout(() => {
                onEnded();
            }, exersiceBreak * 1000);
        }
        return () => {
            clearTimeout(interval);
            clearTimeout(timeout);
        };
        // eslint-disable-next-line
    }, [playing]);

    return (
        <div className="exercise-break">
            <div className="exercise-break-counter">{pad(exersiceBreak)}</div>
            <div className="exercise-break-progress">
                <CircularProgress total={length} value={exersiceBreak} />
            </div>
        </div>
    );
};

export default ExerciseBreak;
