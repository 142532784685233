import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useSWR, { cache } from 'swr';
import RecipeCard from '../../components/Recipe/RecipeCard/RecipeCard';
import BackButton from '../../components/UI/Button/BackButton';
import Loader from '../../components/UI/Loader/Loader';
import Tagline from '../../components/UI/Tagline/Tagline';
import useNotification from '../../hooks/useNotification';
import useViewport from '../../hooks/useViewport';
import useWeekMeals from '../../hooks/useWeekMeals';
import { getRecipe } from '../../moduls/mealPlan';
import api from '../../services/api';
import { DishCoursesType, RecipeDataType } from '../../services/types';
import { classes } from '../../services/utils';
import './MealPlanMenuDetail.scss';

const MealPlanMenuDetail = () => {
    const { breakpoint } = useViewport();
    const [favorite, setFavorite] = useState(false);
    const location = useLocation();
    const { open: openNotification } = useNotification();
    const type = new URLSearchParams(location.search).get('type');
    const { id }: { id: string } = useParams();
    const { data: recipe } = useSWR<RecipeDataType>(['recipe', +id], getRecipe, {
        revalidateOnMount: !cache.has(['recipe', +id]),
    });
    const { data: meals, mutate } = useWeekMeals();

    useEffect(() => {
        const favoriteMeals = meals?.favorite_recipes;
        if (favoriteMeals) {
            if (favoriteMeals?.filter((meal) => meal.id.toString() === id && meal.type.toString() === type).length) {
                setFavorite(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meals]);

    const handleFavorite = () => {
        if (!favorite) {
            setFavorite(true);
            api.post(`/diet-plan/diet/recipes/${id}/favorite`, {
                type,
            })
                .then(() => mutate())
                .catch((err) => {
                    openNotification(err.response.data.message, 'error');
                    setFavorite(false);
                });
        } else {
            setFavorite(false);
            api.post(`/diet-plan/diet/recipes/${id}/unfavorite`, {
                type,
            })
                .then(() => mutate())
                .catch(() => {
                    setFavorite(true);
                });
        }
    };

    if (recipe === undefined || meals === undefined) return <Loader />;

    return (
        <div className="container-row between-lg page-row">
            <div className="h-mb-15">
                <BackButton />
            </div>
            {breakpoint === 'small' && (
                <div className="h-text-center">
                    <Tagline size="small" className="h-color-medium-gray h-mb-1">
                        {type
                            ? DishCoursesType[Object.keys(DishCoursesType)[+type] as keyof typeof DishCoursesType]
                            : recipe.description}
                    </Tagline>
                    <h1>{recipe.name}</h1>
                    {type && <FavoriteButton favorite={favorite} onChange={handleFavorite} />}
                </div>
            )}
            <div className="col-md-6 h-mb-sm-2">
                <RecipeCard image={recipe.image || ''} ingredients={recipe.ingredients} />
            </div>
            <div className="col-lg-5 col-md-6">
                {breakpoint !== 'small' && (
                    <>
                        <Tagline size="small" className="h-color-medium-gray h-mb-1">
                            {type
                                ? DishCoursesType[Object.keys(DishCoursesType)[+type] as keyof typeof DishCoursesType]
                                : recipe.description}
                        </Tagline>
                        <h1>{recipe.name}</h1>
                        {type && <FavoriteButton favorite={favorite} onChange={handleFavorite} />}
                    </>
                )}
                <h3 className="menu-detail-procedure-title">Postup</h3>
                <div>
                    <p className="menu-detail-procedure">{recipe.procedure}</p>
                </div>
            </div>
        </div>
    );
};

const FavoriteButton: React.FC<{ favorite: boolean; onChange: () => void }> = ({ favorite, onChange }) => {
    return (
        <span className="menu-detail-heart-wrapper">
            <button
                className={classes('menu-detail-heart', { 'is-active': favorite })}
                onClick={onChange}
                title={favorite ? 'Odstranit z oblíbených' : 'Označit jako oblíbené'}
            ></button>
            {favorite ? 'Odstranit z oblíbených' : 'Označit jako oblíbené'}
        </span>
    );
};

export default MealPlanMenuDetail;
