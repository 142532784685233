import React from 'react';
import { classes } from '../../../services/utils';
import IconTitle from '../../UI/IconTitle/IconTitle';
import './RecipeListItem.scss';

interface RecipeListItemPropsType {
    title: string;
    icon: string;
    bordered?: boolean;
    smallIcon?: boolean;
}

const RecipeListItem: React.FC<RecipeListItemPropsType> = ({ title, icon, bordered, smallIcon, children }) => {
    return (
        <div className="recipe-list-item">
            <IconTitle icon={icon} smallIcon={smallIcon}>
                {title}
            </IconTitle>
            <table className={classes('', { 'is-bordered': !!bordered })}>
                <thead></thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );
};

export default RecipeListItem;
