import useSWR, { cache } from 'swr';
import { getWeekMeals } from '../moduls/mealPlan';
import { MealWeekRecipesType } from '../services/types';

const useWeekMeals = () => {
    const data = useSWR<MealWeekRecipesType>('week-meals', getWeekMeals, {
        revalidateOnMount: !cache.has('week-meals'),
    });
    return data;
};

export default useWeekMeals;
