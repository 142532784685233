import { useEffect } from 'react';
import { ProfileDataType } from '../services/types';

const useSupportbox = (profile?: ProfileDataType) => {
    useEffect(() => {
        let chatScript: HTMLScriptElement;
        if (profile?.support_box && !document.getElementById('smartsupp-widget-container')) {
            chatScript = document.createElement('script');
            chatScript.type = 'text/javascript';
            chatScript.innerHTML = `var _smartsupp = _smartsupp || {};
            _smartsupp.key = '0d5ab204743c7756f5298fd546d7f498f8979127';
            window.smartsupp||(function(d) {
                var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
                s=d.getElementsByTagName('script')[0];c=d.createElement('script');
                c.type='text/javascript';c.charset='utf-8';c.async=true;
                c.src='//www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
            })(document);`;

            document.body.appendChild(chatScript);
        }

        return () => {
            if (profile?.support_box && document.getElementById('smartsupp-widget-container') && chatScript) {
                document.body.removeChild(chatScript);
                const frame = document.getElementById('smartsupp-widget-container');
                frame?.parentNode?.removeChild(frame);
            }
        };
    }, [profile]);
};

export default useSupportbox;
