import React from 'react';
import NumberInput from '../../../components/UI/NumberInput/NumberInput';

interface ProfileSetup01PropsType {
    height: string;
    setHeight: (value: string) => void;
    weight: string;
    setWeight: (value: string) => void;
}

const ProfileSetup01: React.FC<ProfileSetup01PropsType> = ({ height, setHeight, weight, setWeight }) => {
    return (
        <>
            <h1>Vaše postava</h1>
            <NumberInput value={height} onChange={setHeight} unit="cm" placeholder="Zadejte Vaši výšku" />
            <NumberInput value={weight} onChange={setWeight} unit="kg" placeholder="Zadejte Vaši váhu" />
        </>
    );
};

export default ProfileSetup01;
