import React from 'react';
import { createArray } from '../../../services/utils';
import './ProfileAvatar.scss';

const ProfileAvatar: React.FC<{ name: string; photo: string | null; fontSize: number }> = ({
    name,
    photo,
    fontSize,
}) => {
    if (photo) {
        return <div className="profile-avatar" style={{ backgroundImage: `url('${photo}')` }}></div>;
    } else {
        return (
            <div className="profile-avatar" style={{ fontSize: `${fontSize}rem` }}>
                {createArray(name.split(' ').length).map((index) => name.split(' ')[index][0])}
            </div>
        );
    }
};

export default ProfileAvatar;
