import React from 'react';
import { Link } from 'react-router-dom';
import { ColorsType } from '../../../services/types';
import { classes } from '../../../services/utils';
import './TrainingCard.scss';

interface TrainingCardPropsType {
    link: string;
    color?: ColorsType;
    backgroundText?: string;
    image?: string;
    icon?: string;
    className?: string;
}

const TrainingCard: React.FC<TrainingCardPropsType> = (props) => {
    const { link, color = 'yellow', backgroundText, image, icon, className } = props;
    return (
        <Link to={link} className={classes(`training-card is-${color}`, { [className || '']: !!className })}>
            <div className="training-card-square">
                <div className="training-card-content">
                    <span className="training-card-text">{backgroundText}</span>
                    <div className="training-card-image" style={{ backgroundImage: `url('${image}')` }}></div>
                </div>
            </div>
            <div className="training-card-button">
                {!!icon && <i className={`icon-${icon}`} />}
                <div>{props.children}</div>
            </div>
        </Link>
    );
};

export default TrainingCard;
