import React, { useEffect, useState } from 'react';
import './Membership.scss';
import Loader from '../UI/Loader/Loader';

const utm = '?utm_source=app&utm_medium=card&utm_campaign=app';

interface MembershipCmsData {
    data: {
        title: string;
        subtitle: string;
        hint: string;
        memberships:
            {
                title: string;
                url: string;
                discountPrice: string;
                finalPrice: string;
                saving: string;
                isFavourite: boolean;
            }[];
    } | null;
}

const Membership = () => {
    const [loading, setLoading] = useState(true)
    const [cmsData, setCmsData] = useState<MembershipCmsData | null>(null);

    useEffect(()=>{
        getCmsData().then((data)=>{
            setCmsData(data);
            setLoading(false);
        });
    },[])

    return (loading ?
            (<Loader />) :
            (<div className='container membership'>
                {(!cmsData || !cmsData.data)? (
                    <div className='membership-container'>
                        <h2 className='membership-title'>NELZE NAČÍST DATA O ČLENSKÝCH PROGRAMECH. ZKUSTE TO PROSÍM POZDĚJI</h2>
                        <h3 className='membership-subtitle'>
                            V případě že problémy přetrvávají nás kontaktujte e-mailem
                        </h3>
                    </div>
                ):(
                    <div className='membership-container'>
                    <h2 className='membership-title'>{cmsData.data.title}</h2>
                    <h3 className='membership-subtitle'>{cmsData.data.subtitle}</h3>
                    <p className='membership-hint'>{cmsData.data.hint}</p>
                    <div className='membership-prices-container'>
                        {Array.isArray(cmsData.data.memberships) && cmsData.data.memberships.map(({ title, url, discountPrice, finalPrice, saving, isFavourite }, index) => {
                            return (
                                <div
                                    key={index}
                                    className={
                                        isFavourite
                                            ? 'membership-prices-card membership-prices-favourite'
                                            : 'membership-prices-card'
                                    }
                                >
                                    <div className='membership-card-title-container'>
                                        <h4 className='membership-card-title'>{title}</h4>
                                        <hr />
                                    </div>
                                    {isFavourite && <div className='membership-card-favourite'>Nejoblíbenější</div>}
                                    <p className='membership-card-price'>
                                        <span className='membership-card-discount'>{discountPrice}</span>
                                        {finalPrice}
                                    </p>
                                    <p className='membership-card-savings'>{saving}</p>
                                    <a href={`${url}${utm}`} className='button is-yellow membership-card-button'>
                                        OBJEDNAT ČLENSTVÍ
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
                )}

            </div>)
    );
};

export const getCmsData = async (): Promise<MembershipCmsData> => {
    const data = await fetch(`${process.env.REACT_APP_CMS_URL}/api/membership-section?populate=deep`,{method:"GET"});
    const membershipData = await data.json();
    return ({
        data: membershipData?.data?.attributes ?? null
    });
};

export default Membership;
