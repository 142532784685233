import React from 'react';
import './BackButton.scss';
import { Link, useHistory } from 'react-router-dom';
import { classes } from '../../../services/utils';
import { ReactComponent as Chevron } from '../../../assets/images/icons/ic-chevron-left.svg';

const BackButton: React.FC<{ to?: string; className?: string }> = ({ to, className, children }) => {
    const history = useHistory();

    if (to) {
        return (
            <Link to={to} className={classes('back-button', { [className || '']: !!className })}>
                <Chevron />
                {children || 'Zpět'}
            </Link>
        );
    } else {
        return (
            <button onClick={history.goBack} className={classes('back-button', { [className || '']: !!className })}>
                <Chevron />
                {children || 'Zpět'}
            </button>
        );
    }
};

export default BackButton;
