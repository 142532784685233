import React from 'react';
import { RecipeDataType } from '../../../services/types';
import './RecipeCard.scss';

interface RecipeCardPropsType {
    image: string;
    ingredients: RecipeDataType['ingredients'];
}

const RecipeCard: React.FC<RecipeCardPropsType> = ({ image, ingredients }) => {
    return (
        <div className="recipe-card">
            <div className="recipe-card-image" style={{ backgroundImage: `url('${image}')` }}></div>
            <div className="recipe-card-content">
                <div className="recipe-card-content-box">
                    <h4>Potravina</h4>
                    <ul>
                        {ingredients.map((ingredient, index) => (
                            <li
                                key={ingredient.name + index}
                                size-content={
                                    `${ingredients[index].group_amount} ${ingredients[index].group_name}`.length >
                                    `${ingredients[index].amount} ${ingredients[index].name}`.length
                                        ? `${ingredients[index].group_amount} ${ingredients[index].group_name}`
                                        : `${ingredients[index].amount} ${ingredients[index].name}`
                                }
                            >
                                <span>
                                    <strong>{ingredient.amount}</strong> {ingredient.name}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="recipe-card-content-box">
                    <h4>Množství z dané skupiny</h4>
                    <ul>
                        {ingredients.map((ingredient, index) => (
                            <li
                                key={ingredient.group_name + index}
                                size-content={
                                    `${ingredients[index].group_amount} ${ingredients[index].group_name}`.length >
                                    `${ingredients[index].amount} ${ingredients[index].name}`.length
                                        ? `${ingredients[index].group_amount} ${ingredients[index].group_name}`
                                        : `${ingredients[index].amount} ${ingredients[index].name}`
                                }
                            >
                                <span>
                                    <strong>{ingredient.group_amount}</strong> {ingredient.group_name}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default RecipeCard;
