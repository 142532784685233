import React from 'react';
import { Link } from 'react-router-dom';
import { routesURLs } from '../../../Routes';
import './Footer.scss';

import logo from '../../../assets/images/brands/logo-white-horizontal.png';

const Footer = () => {
    return (
        <div className="footer">
            <Link to={routesURLs.dashboard} className="footer-logo">
                <img src={logo} alt="Strong beauty" />
            </Link>
            <div className="footer-nav-wrapper">
                <ul className="footer-nav">
                    <li>
                        <a href="https://napoveda.strongbeauty.cz/" target="_blank" rel="noopener noreferrer">
                            Nápověda
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.strongbeauty.cz/clenska-aplikace/roadmapa/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Vaše návrhy
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.strongbeauty.cz/clenska-aplikace/zreknuti-se-odpovednosti/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Zřeknutí se odpovědnosti
                        </a>
                    </li>
                    <li>
                        <span className="h-color-medium-gray">Kontakt:</span>{' '}
                        <a href="mailto:kontakt@strongbeauty.cz">kontakt@strongbeauty.cz</a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;

// Kontakt: kontakt@strongbeauty.cz
