import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import './Tutorial.scss';
import { getConfig } from '../../../moduls/global';
import useSWR, { cache } from 'swr';

interface TutorialPropsType {
    isOpen: boolean;
    close: () => void;
}

const Tutorial: React.FC<TutorialPropsType> = ({ isOpen, close }) => {
    const [controls, setControls] = useState(false);
    const [muted, setMuted] = useState(true);
    const [playing, setPlaying] = useState(false);
    const video = useRef<ReactPlayer>(null);

    const data = useSWR<[{ id: number; media: { file_name: string; name: string; url: string; mime_type: string }[] }]>(
        'configs',
        getConfig,
        {
            revalidateOnMount: !cache.has('configs'),
        },
    );
    const videos = data?.data?.[0]?.media.filter((item) => item.mime_type.includes('video')).map((item) => item.url)[0];
    const poster = data?.data?.[0]?.media.filter((item) => item.mime_type.includes('image'))?.[0]?.url;

    useEffect(() => {
        if (!isOpen) {
            setControls(false);
            setPlaying(false);
            setMuted(true);
        }
    }, [isOpen]);

    if (!data?.data?.length) return null;

    const setFullscreen = () => {
        var videoEl = document.querySelector('.tutorial-video-wrapper > video');
        if (videoEl) {
            if (videoEl.requestFullscreen) {
                videoEl.requestFullscreen();
            } else if (videoEl.mozRequestFullScreen) {
                videoEl.mozRequestFullScreen();
            } else if (videoEl.webkitRequestFullscreen) {
                videoEl.webkitRequestFullscreen();
            } else if (videoEl.msRequestFullscreen) {
                videoEl.msRequestFullscreen();
            }
        }
    };

    const onClose = () => {
        close();
        localStorage.setItem('tutorial-shown', 'true');
        if (video.current) video.current.seekTo(0);
    };

    const playTutorial = () => {
        video.current?.seekTo(0);
        setControls(true);
        setPlaying(true);
        setMuted(false);
        setFullscreen();
        localStorage.setItem('tutorial-shown', 'true');
    };

    return (
        <Modal close={onClose} isOpen={isOpen}>
            <h2>Podívejte se na tutoriál</h2>
            <div className="tutorial-video">
                <ReactPlayer
                    className="tutorial-video-wrapper"
                    ref={video}
                    config={{
                        file: {
                            attributes: {
                                poster,
                            },
                        },
                    }}
                    url={videos}
                    controls={controls}
                    playing={playing}
                    muted={muted}
                    width="100%"
                    height="100%"
                    playsinline
                />
            </div>
            <Button onClick={playTutorial}>Zhlédnout tutorial</Button>
            <br />
            <Button onClick={onClose} size="link" className="h-mt-15">
                Podívat se jindy
            </Button>
        </Modal>
    );
};

export default Tutorial;
