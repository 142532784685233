import React from 'react'

const NewsSkeleton = () => {
    return (
        <div className="h-pr-4">
            <div className="skeleton-text h-mb-1 h-mr-1"></div>
            <div className="skeleton-text"></div>
            <div className="skeleton-text"></div>
            <div className="skeleton-text"></div>
        </div>
    )
}

export default NewsSkeleton
