import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import './MealPlayVideo.scss';

interface MealPlayVideoPropsType {
    url: string;
    isOpen: boolean;
    close: () => void;
}

const MealPlayVideo: React.FC<MealPlayVideoPropsType> = ({ url, isOpen, close }) => {
    const [controls, setControls] = useState(false);
    const [muted, setMuted] = useState(true);
    const [playing, setPlaying] = useState(false);
    const video = useRef<ReactPlayer>(null);

    useEffect(() => {
        if (!isOpen) {
            setControls(false);
            setPlaying(false);
            setMuted(true);
        }
    }, [isOpen]);

    const setFullscreen = () => {
        var videoEl = document.querySelector('.mealplan-video-wrapper > video');
        if (videoEl) {
            if (videoEl.requestFullscreen) {
                videoEl.requestFullscreen();
            } else if (videoEl.mozRequestFullScreen) {
                videoEl.mozRequestFullScreen();
            } else if (videoEl.webkitRequestFullscreen) {
                videoEl.webkitRequestFullscreen();
            } else if (videoEl.msRequestFullscreen) {
                videoEl.msRequestFullscreen();
            }
        }
    };

    const onClose = () => {
        close();
        if (video.current) video.current.seekTo(0);
    };

    const playMealPlayVideo = () => {
        video.current?.seekTo(0);
        setControls(true);
        setPlaying(true);
        setMuted(false);
        setFullscreen();
    };

    return (
        <Modal close={onClose} isOpen={isOpen}>
            <h2>Podívejte se na instruktážní video</h2>
            <div className="mealplan-video">
                <ReactPlayer
                    className="mealplan-video-wrapper"
                    ref={video}
                    url={url || 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4'}
                    controls={controls}
                    playing={playing}
                    muted={muted}
                    width="100%"
                    height="100%"
                />
            </div>
            <Button onClick={playMealPlayVideo}>Spustit video</Button>
            <br />
            <Button onClick={onClose} size="link" className="h-mt-15">
                Zavřít
            </Button>
        </Modal>
    );
};

export default MealPlayVideo;
