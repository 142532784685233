import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { classes } from '../../../services/utils';
import './Button.scss';

interface ButtonType {
    to?: string;
    onClick?: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => void;
    color?: 'yellow' | 'pink' | 'blue' | 'purple' | 'gray' | 'white' | 'black' | 'alert' | 'warning';
    size?: 'small' | 'link';
    disabled?: boolean;
    icon?: string;
    loading?: boolean;
    className?: string;
    reference?: RefObject<HTMLButtonElement>;
    type?: 'submit' | 'button';
}

const Button: React.FC<ButtonType> = (props) => {
    const {
        className,
        onClick,
        color = 'yellow',
        size,
        children,
        disabled,
        icon,
        loading,
        type = 'button',
        reference,
        to,
    } = props;

    const addClasses = {
        [className || '']: !!className,
        'is-loading': !!loading,
        [`is-${color}`]: !!color,
        [`is-${size}`]: !!size,
    };

    if (to) {
        return (
            <Link to={to} className={classes('button', addClasses)} onClick={onClick}>
                {children}
                {icon && <i className={`icon-${icon}`}></i>}
            </Link>
        );
    } else {
        return (
            <button
                type={type}
                ref={reference}
                onClick={disabled || loading ? () => {} : onClick}
                className={classes('button', addClasses)}
                disabled={disabled}
                title={loading ? 'Načítám..' : ''}
            >
                {children}
                {icon && <i className={`icon-${icon}`}></i>}
            </button>
        );
    }
};

export default Button;
