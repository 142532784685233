import React, { useCallback, useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import { GlobalTypes, useGlobalState } from '../../../contexts/global';
import useProfile from '../../../hooks/useProfile';
import { editProfile } from '../../../moduls/profile';
import { ProfileDataType } from '../../../services/types';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import useNotification from '../../../hooks/useNotification';

const ProfileEditImageModal: React.FC<{
    close: () => void;
    isOpen: boolean;
}> = ({ close, isOpen }) => {
    const { state, dispatch } = useGlobalState();
    const { data: profile, mutate } = useProfile();
    const { open: openNotification } = useNotification();
    const [image, setImage] = useState<string | null>('');

    const changeImage = async (img: string | null) => {
        if (profile?.is_testing) {
            openNotification('Údaje testovacího účtu nelze měnit.', 'error');
            return;
        }

        dispatch({ type: GlobalTypes.ToggleLoader, loader: 'editPhoto' });
        await editProfile({
            email: profile!.email,
            name: profile!.name,
            password: '',
            password_confirmation: '',
            profile_photo: img!,
        });
        mutate({
            ...(profile as ProfileDataType),
            profile_photo: img,
        });
        dispatch({ type: GlobalTypes.ToggleLoader });
        onClose();
    };

    const onImageSelect = useCallback((acceptedFiles) => {
        var reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = function () {
            if (reader.result) setImage(reader.result.toString());
        };
    }, []);

    const onClose = () => {
        close();
        setTimeout(() => {
            setImage('');
        }, 300);
    };

    if (!profile) return null;

    return (
        <Modal close={onClose} isOpen={isOpen}>
            <div className="profile-avatar-wrapper">
                <ProfileAvatar name={profile.name} photo={image || profile.profile_photo} fontSize={2} />
            </div>
            <h3 className="h-mb-2">Změnit profilovou fotku</h3>
            <div className="h-mb-2">
                <label htmlFor="edit-profile-photo" className="button is-yellow">
                    Nahrát profilovou fotku
                    <input type="file" id="edit-profile-photo" onChange={(e) => onImageSelect(e.target.files)} />
                </label>
            </div>
            {!!profile.profile_photo && (
                <Button onClick={() => changeImage(null)} size="link" className="h-mb-1">
                    Smazat aktuální fotku
                </Button>
            )}
            <hr />
            <Button
                onClick={() => changeImage(image)}
                color="pink"
                disabled={image === ''}
                loading={state.loader === 'editPhoto'}
            >
                Uložit změny
            </Button>
        </Modal>
    );
};

export default ProfileEditImageModal;
