import './FormInputWrapper.scss';
import React from 'react';
import { FieldMetaProps } from 'formik';
import { classes } from '../../../../services/utils';

interface PropsType {
    className?: string;
    meta: FieldMetaProps<unknown>;
}

const FormInputWrapper: React.FC<PropsType> = (props) => {
    const { className, meta } = props;
    return (
        <div
            className={classes('form-field-wrapper', {
                'is-invalid': meta.touched && !!meta.error,
                [className || '']: !!className,
            })}
        >
            {props.children}
            {meta.touched && meta.error && <div className="form-error-messages">{meta.error}</div>}
        </div>
    );
};

export default FormInputWrapper;
