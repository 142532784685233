import React, { useEffect, useRef, useState } from 'react';
import useViewport from '../../../hooks/useViewport';
import { ColorsType } from '../../../services/types';
import { classes, plural } from '../../../services/utils';
import './TrainingRow.scss';

interface TrainingRowPropsType {
    image: string | null;
    icon?: string;
    title: string;
    description: string;
    color?: ColorsType;
    repetitions: number | null;
    repetitions_unit: string;
    series: number;
    tag?: {
        text: string;
        color: ColorsType;
    };
    playVideoTrigger?: () => void;
}

const TrainingRow: React.FC<TrainingRowPropsType> = (props) => {
    const {
        image,
        icon = 'dumbbells-black',
        title,
        description,
        color = 'yellow',
        tag,
        repetitions,
        repetitions_unit,
        series,
        playVideoTrigger,
    } = props;
    const { breakpoint } = useViewport();
    const [open, isOpen] = useState(false);

    const [height, setHeight] = useState(0);
    const descriptionWrapper = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (descriptionWrapper.current !== null) {
            setHeight(descriptionWrapper.current.clientHeight);
        }
    }, [descriptionWrapper]);

    return (
        <div className={`training-row is-${color}`}>
            <div
                className={classes(`training-row-image icon-${icon}`, { 'is-icon': !image })}
                style={{ backgroundImage: image ? `url('${image}')` : '' }}
            >
                {!!tag && <span className={`is-${tag.color}`}>{tag.text}</span>}
                {!!playVideoTrigger && (
                    <button
                        onClick={playVideoTrigger}
                        className={`training-row-play h-background-${color}`}
                        title="Přehrát video s ukázkou cviku"
                    >
                        Přehrát video
                    </button>
                )}
            </div>
            <div className="training-row-content">
                <h3>{title}</h3>
                <ul className="training-row-spec">
                    {!!series && (
                        <li>
                            <strong>{series}</strong> {plural(series, 'série', 'série', 'sérií')}
                        </li>
                    )}
                    {repetitions && (
                        <li>
                            <strong>{repetitions}</strong> {repetitions_unit}
                        </li>
                    )}
                </ul>

                {(breakpoint === 'small' || breakpoint === 'medium') && <ButtonToggle isOpen={isOpen} open={open} />}
            </div>
            <div className="training-row-description" style={{ height: open ? height : '' }}>
                <div ref={descriptionWrapper}>
                    <h4>Provedení</h4>
                    <p>{description}</p>
                    {breakpoint === 'large' && <ButtonToggle isOpen={isOpen} open={open} />}
                </div>
            </div>
        </div>
    );
};

const ButtonToggle = ({ isOpen, open }: { isOpen: (open: boolean) => void; open: boolean }) => {
    return (
        <button
            type="button"
            onClick={() => isOpen(!open)}
            className={classes('training-row-toggle', { 'is-open': open })}
        >
            Zobrazit / skrýt provedení
        </button>
    );
};

export default TrainingRow;
