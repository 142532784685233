import React, { useEffect, useState } from 'react';
import RadioInput from '../../components/Form/Inputs/RadioInput/RadioInput';
import BackButton from '../../components/UI/Button/BackButton';
import Button from '../../components/UI/Button/Button';
import Tagline from '../../components/UI/Tagline/Tagline';
import TrainingCard from '../../components/Training/TrainingCard/TrainingCard';
import ForwardButton from '../../components/UI/Button/ForwardButton';
import Modal from '../../components/Modal/Modal';
import './Components.scss';
import TrainingRow from '../../components/Training/TrainingRow/TrainingRow';
import RecipeCard from '../../components/Recipe/RecipeCard/RecipeCard';
import RecipeListItem from '../../components/Recipe/RecipeListItem/RecipeListItem';
import IconTitle from '../../components/UI/IconTitle/IconTitle';
import FormInputText from '../../components/Form/Inputs/FormInputText/FormInputText';
import { Formik, Form } from 'formik';
import OverviewList from '../../components/OverviewList/OverviewList';
import { routesURLs } from '../../Routes';

import absPink from '../../assets/images/icons/app/abs-pink.svg';
import weightDownPink from '../../assets/images/icons/app/weight-down-pink.svg';
import domiTrainer from '../../assets/images/backgrounds/domi.png';
import Loader from '../../components/UI/Loader/Loader';
import CircularProgress from '../../components/UI/CircularProgress/CircularProgress';

const Components = () => {
    const [radioValue, setRadioValue] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        console.log(radioValue);
    }, [radioValue]);

    return (
        <>
            <div className="container h-mb-5">
                <h1>Components</h1>
                <h3>Colors</h3>
                <div className="color-box h-background-yellow">
                    <div>yellow</div>
                    <div>#F6D98F</div>
                </div>
                <div className="color-box h-background-pink">
                    <div>pink</div>
                    <div>#FAB0AD</div>
                </div>
                <div className="color-box h-background-blue">
                    <div>blue</div>
                    <div>#77C7EC</div>
                </div>
                <div className="color-box h-background-purple">
                    <div>purple</div>
                    <div>#C4AFFF</div>
                </div>
                <div className="color-box h-background-black">
                    <div>black</div>
                    <div>#2F3345</div>
                </div>
                <div className="color-box h-background-dark-gray">
                    <div>dark-gray</div>
                    <div>#4F556C</div>
                </div>
                <div className="color-box h-background-medium-gray">
                    <div>medium-gray</div>
                    <div>#9799A2</div>
                </div>
                <div className="color-box h-background-semi-gray h-color-black">
                    <div>semi-gray</div>
                    <div>#D5D6DA</div>
                </div>
                <div className="color-box h-background-light-gray h-color-black">
                    <div>light-gray</div>
                    <div>#EBEBED</div>
                </div>
                <div className="color-box h-background-lightest-gray h-color-black">
                    <div>lightest-gray</div>
                    <div>#F4F4F5</div>
                </div>
                <div className="color-box h-background-white h-color-black">
                    <div>white</div>
                    <div>#FFFFFF</div>
                </div>

                <hr />
                <h3>Typography</h3>
                <h1>h1 title</h1>
                <h2>h2 title</h2>
                <h3>h3 title</h3>
                <h4>h4 title</h4>
                <Tagline>Tagline 16</Tagline>
                <Tagline size="small" className="h-color-medium-gray h-mb-15">
                    Tagline 14
                </Tagline>
                <IconTitle icon="meat-pink">Icon title</IconTitle>
                <IconTitle icon="dumbbells-black" smallIcon={true}>
                    Icon title small
                </IconTitle>
                <div className="container-row">
                    <div className="col-lg-7">
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quod beatae nemo nisi distinctio ducimus quo
                            animi pariatur porro a{' '}
                            <a href="/">iusto sapiente facilis</a> doloremque
                            eius adipisci. Laboriosam fuga, asperiores totam
                            repudiandae eum sapiente quidem impedit molestiae
                            similique, maiores dicta delectus sit cumque
                            excepturi dolore et ut nemo autem fugit?
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. In sit eius facere vel placeat omnis illum
                            similique asperiores dolorum! Iure magni dignissimos
                            adipisci aliquam ullam.
                        </p>
                    </div>
                </div>

                <hr />
                <h3>Buttons</h3>
                <Button className="h-mr-05 h-mb-05">yellow button</Button>
                <Button color="pink" className="h-mr-05 h-mb-05">
                    pink button
                </Button>
                <Button color="blue" className="h-mr-05 h-mb-05">
                    blue button
                </Button>
                <Button color="purple" className="h-mr-05 h-mb-05">
                    purple button
                </Button>
                <Button color="white" className="h-mr-05 h-mb-05">
                    white button
                </Button>
                <Button color="gray" className="h-mr-05 h-mb-05">
                    gray button
                </Button>
                <Button icon="play-black" className="h-mr-05 h-mb-05">
                    icon button
                </Button>
                <Button size="small" className="h-mr-05 h-mb-05">
                    small button
                </Button>
                <Button
                    size="small"
                    color="gray"
                    icon="download-white"
                    className="h-mr-05 h-mb-05"
                >
                    small button
                </Button>
                <Button size="link" className="h-mr-05 h-mb-05">
                    link button
                </Button>
                <BackButton to="/components">Back button</BackButton>
                <ForwardButton to="/components">Forward button</ForwardButton>

                <hr />
                <h3>Training Card</h3>
                <div className="container-row">
                    <div className="col-lg-3 col-md-6">
                        <TrainingCard
                            link="/components"
                            image={domiTrainer}
                            backgroundText="ABS"
                            icon="dumbbells-black"
                        >
                            Trénink 01
                        </TrainingCard>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <TrainingCard
                            link="/components"
                            color="blue"
                            image={domiTrainer}
                            backgroundText="02"
                            icon="dumbbells-black"
                        >
                            Trénink 02
                        </TrainingCard>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <TrainingCard
                            link="/components"
                            color="pink"
                            image={domiTrainer}
                            backgroundText="HIIT"
                            icon="dumbbells-black"
                        >
                            Trénink 03
                        </TrainingCard>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <TrainingCard
                            link="/components"
                            color="black"
                            image={domiTrainer}
                            backgroundText="CARDIO"
                            icon="dumbbells-black"
                        >
                            Trénink 04
                        </TrainingCard>
                    </div>
                </div>

                <hr />
                <h3>Training Row</h3>
                <TrainingRow
                    image="https://picsum.photos/200/200"
                    title="Zakopávání s míčem"
                    description="Polož se na zem. Paty si dej na velký míč přibližně na střed. Ruce natáhni podél těla. S výdechem přitahuj míč k zadku a s nádechem nohy natahuj. Těžší varianta je, že držíš tělo od kolen po ramena v jedné rovině. Lehčí varianta je, že máš zadek těsně nad zemí a pouze krčíš kolena. Buď si zvol jednu variantu, se kterou odcvičíš 16 opakování, anebo si dej 8 těžších + 8 lehčích (doporučeno)."
                    repetitions_unit={'jksdf'}
                    repetitions={10}
                    series={3}
                    tag={{ color: 'yellow', text: 'A' }}
                    playVideoTrigger={() => console.log('boom')}
                />

                <hr />
                <div className="container-row">
                    <div className="col-lg-6">
                        <h3>Recipe Card</h3>
                        <RecipeCard
                            image={'https://picsum.photos/700/501'}
                            ingredients={[
                                {
                                    amount: '2',
                                    name: 'Test',
                                    group_amount: '43g',
                                    group_name: 'Other',
                                },
                            ]}
                        />
                    </div>
                    <div className="col-lg-6">
                        <h3>Overview list</h3>
                        <OverviewList
                            items={[]}
                            color="pink"
                            button="Stravovací plán"
                            buttonIcon="knife-fork-black"
                            route={routesURLs.components}
                        />
                        <OverviewList
                            items={[]}
                            color="blue"
                            button="Tréninkový plán"
                            buttonIcon="dumbbells-black"
                            route={routesURLs.components}
                        />
                    </div>
                </div>

                <hr />
                <h3>Recipe list item</h3>
                <div className="container-row">
                    <div className="col-lg-4">
                        <RecipeListItem title="Bílkoviny" icon="meat-pink">
                            <tr>
                                <th>Vejce</th>
                                <th>7ks</th>
                            </tr>
                            <tr>
                                <th>Krůtí prsa</th>
                                <th>130 g</th>
                            </tr>
                        </RecipeListItem>
                    </div>
                    <div className="col-lg-4">
                        <RecipeListItem
                            title="Bílkoviny"
                            icon="meat-pink"
                            bordered={true}
                            smallIcon={true}
                        >
                            <tr>
                                <th>Vejce</th>
                                <th>7ks</th>
                            </tr>
                            <tr>
                                <th>Krůtí prsa</th>
                                <th>130 g</th>
                            </tr>
                        </RecipeListItem>
                    </div>
                </div>

                <hr />
                <div className="h-text-center">
                    <h3>Modal window</h3>
                    <Button onClick={() => setModalOpen(true)}>
                        Open modal window
                    </Button>
                </div>
                <Modal isOpen={modalOpen} close={() => setModalOpen(false)}>
                    <h2>Modal window content</h2>
                    <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Maiores obcaecati architecto libero ullam, tenetur
                        iure nisi fugiat! Voluptatum, nemo eos!
                    </p>
                    <Button onClick={() => setModalOpen(false)}>
                        Potvrdit
                    </Button>
                    <br />
                    <Button
                        size="link"
                        className="h-color-medium-gray h-mt-125"
                    >
                        Příště
                    </Button>
                </Modal>

                <hr />
                <div className="container-row center-md">
                    <div className="col-md-6">
                        <h3>Inputs</h3>
                        <Formik
                            initialValues={{ name: '' }}
                            onSubmit={(values) => console.log(values)}
                        >
                            {() => (
                                <Form>
                                    <FormInputText
                                        name="text"
                                        attributes={{
                                            placeholder: 'Napište..',
                                        }}
                                    />
                                    <RadioInput
                                        color="pink"
                                        id="radio-input-1"
                                        image={absPink}
                                        isActive={
                                            radioValue === 'radio-input-1'
                                        }
                                        value="radio-input-1"
                                        name="radio"
                                        onChange={setRadioValue}
                                    >
                                        Radio input option
                                    </RadioInput>
                                    <RadioInput
                                        color="blue"
                                        id="radio-input-2"
                                        image={weightDownPink}
                                        isActive={
                                            radioValue === 'radio-input-2'
                                        }
                                        value="radio-input-2"
                                        name="radio"
                                        onChange={setRadioValue}
                                    >
                                        Radio input option 2
                                    </RadioInput>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <CircularProgress total={100} value={40} />
                <Loader />
            </div>
        </>
    );
};

export default Components;
