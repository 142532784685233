import React, { useEffect, useRef, useState } from 'react';
import { CSSProperties } from 'react';
import './Accordion.scss';

import { classes } from '../../../services/utils';

interface IAccordion {
    open: boolean;
    height?: number;
    disableOverflow?: boolean;
    className?: string;
    style?: CSSProperties;
}

const Accordion: React.FC<IAccordion> = ({
    open,
    height,
    disableOverflow = false,
    children,
    style,
    className,
    ...props
}) => {
    const accordionWrapper = useRef<HTMLDivElement>(null);
    const [overflowHidden, setOverflowHidden] = useState(true);
    const [ignoreHeight, setIgnoreHeight] = useState(false);
    const [heightState, setHeightState] = useState(accordionWrapper?.current?.clientHeight ?? 0);

    useEffect(() => {
        setHeightState(accordionWrapper?.current?.clientHeight ?? 0);
        if (!disableOverflow) return;
        if (!open) {
            setIgnoreHeight(false);
            setTimeout(() => {
                setOverflowHidden(true);
            }, 300);
        } else {
            setOverflowHidden(false);
            setTimeout(() => {
                setIgnoreHeight(true);
            }, 300);
        }
    }, [open, disableOverflow]);

    useEffect(() => {
        setHeightState(accordionWrapper?.current?.clientHeight ?? 0);
    }, [children]);

    return (
        <div
            className={classes(['accordion', className], {
                'is-open': open,
                'has-init-height': !!height,
            })}
            style={{
                ...style,
                overflow: disableOverflow ? (overflowHidden ? 'hidden' : 'visible') : undefined,
                height: ignoreHeight
                    ? 'auto'
                    : (open ? heightState : (height ? Math.min(heightState ?? height, height) : 0).toString()) + 'px',
            }}
            {...props}
        >
            <div ref={accordionWrapper}>{children}</div>
        </div>
    );
};

export default Accordion;
