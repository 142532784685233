import React, { useContext, useEffect, useMemo, useState } from 'react';

type ContextProps = {
    width: number;
    height: number;
    breakpoint: 'large' | 'medium' | 'small';
};

const ViewportContext = React.createContext<Partial<ContextProps>>({});

// eslint-disable-next-line
export const ViewportProvider: React.FC = ({ children }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const value = useMemo(
        () => ({
            width,
            height,
            breakpoint: width >= 1024 ? 'large' : width >= 640 ? 'medium' : ('small' as 'large' | 'medium' | 'small'),
        }),
        [width, height],
    );

    return <ViewportContext.Provider value={value}>{children}</ViewportContext.Provider>;
};

const ExportFce = () => {
    const { width, height, breakpoint } = useContext(ViewportContext);
    return { width, height, breakpoint };
};

export default ExportFce;
