import { Formik, Form, FormikHelpers } from 'formik';
import React from 'react';
import { cache, mutate } from 'swr';
import * as Yup from 'yup';
import FormInputText from '../../../components/Form/Inputs/FormInputText/FormInputText';
import FormInputSelect from '../../../components/Form/Inputs/SelectInput/FormInputSelect';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import { editSubscriptionsData } from '../../../moduls/subscriptions';
import { SubscriptionDataType } from '../../../services/types';
import { transformErrors } from '../../../services/utils';

const attributes = {
    first_name: {
        placeholder: 'Vyplňte...',
        label: 'Křestní jméno',
    },
    last_name: {
        placeholder: 'Vyplňte...',
        label: 'Příjmení',
    },
    phone: {
        placeholder: 'Vyplňte...',
        label: 'Telefonní číslo',
    },
    ic: {
        placeholder: 'Vyplňte...',
        label: 'IČO',
    },
    dic: {
        placeholder: 'Vyplňte...',
        label: 'DIČ',
    },
    company: {
        placeholder: 'Vyplňte...',
        label: 'Společnost',
    },
    address_street: {
        placeholder: 'Vyplňte...',
        label: 'Ulice a čp.',
    },
    address_city: {
        placeholder: 'Vyplňte...',
        label: 'Město',
    },
    address_zip: {
        placeholder: 'Vyplňte...',
        label: 'PSČ',
    },
    address_country: {
        placeholder: 'Vyberte..',
        label: 'Vyberte místo konání',
        options: [{ value: '', label: '' }],
    },
};

const ProfileEditSubscriptionDataModal: React.FC<{
    close: () => void;
    isOpen: boolean;
}> = ({ close, isOpen }) => {
    const data: SubscriptionDataType = cache.get('subscription-data');
    if (data === undefined) return <Modal close={close} isOpen={isOpen}></Modal>;

    const initialValues: Omit<SubscriptionDataType, 'countries'> = {
        first_name: data.first_name ?? '',
        last_name: data.last_name ?? '',
        phone: data.phone ?? '',
        ic: data.ic ?? '',
        dic: data.dic ?? '',
        company: data.company ?? '',
        address_street: data.address_street ?? '',
        address_city: data.address_city ?? '',
        address_zip: data.address_zip ?? '',
        address_country: data.address_country,
    };

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('Povinné pole'),
        last_name: Yup.string().required('Povinné pole'),
        phone: Yup.string().required('Povinné pole'),
    });

    const onSubmit = async (
        values: Omit<SubscriptionDataType, 'countries'>,
        { setErrors }: FormikHelpers<Omit<SubscriptionDataType, 'countries'>>,
    ) => {
        return editSubscriptionsData(values)
            .then(() => {
                mutate(
                    'subscription-data',
                    (data: SubscriptionDataType) => ({ ...values, countries: data.countries }),
                    false,
                );
                close();
            })
            .catch((error) => setErrors(transformErrors(error)));
    };

    attributes.address_country.options = data.countries.map((country) => ({
        label: country.name,
        value: country.code,
    }));

    return (
        <Modal close={close} isOpen={isOpen}>
            <h3 className="h-mb-2">Změna fakturačních údajů</h3>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnBlur={false}
            >
                {({ isSubmitting }) => (
                    <Form className="h-text-left">
                        <div className="container-row">
                            <div className="col-md-6">
                                <FormInputText name="first_name" attributes={attributes.first_name} />
                            </div>
                            <div className="col-md-6">
                                <FormInputText name="last_name" attributes={attributes.last_name} />
                            </div>
                            <div>
                                <FormInputText name="phone" attributes={attributes.phone} />
                            </div>
                            <div>
                                <FormInputText name="company" attributes={attributes.company} />
                            </div>
                            <div className="col-md-6">
                                <FormInputText name="ic" attributes={attributes.ic} />
                            </div>
                            <div className="col-md-6">
                                <FormInputText name="dic" attributes={attributes.dic} />
                            </div>
                            <div className="col-md-6">
                                <FormInputText name="address_street" attributes={attributes.address_street} />
                            </div>
                            <div className="col-md-6">
                                <FormInputText name="address_city" attributes={attributes.address_city} />
                            </div>
                            <div className="col-md-6">
                                <FormInputText name="address_zip" attributes={attributes.address_zip} />
                            </div>
                            <div className="col-md-6">
                                <FormInputSelect name="address_country" attributes={attributes.address_country} />
                            </div>
                            <div className="h-text-center h-mt-15">
                                <Button onClick={close} size="link" className="h-mr-15">
                                    Zavřít
                                </Button>
                                <Button type="submit" color="yellow" className="h-mb-15" loading={isSubmitting}>
                                    Uložit
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ProfileEditSubscriptionDataModal;
