import React from 'react';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/UI/Button/Button';

const ProfileDeleteModal: React.FC<{
    close: () => void;
    isOpen: boolean;
    deleteUser: () => void;
    loading: boolean;
}> = ({ close, isOpen, deleteUser, loading }) => {
    return (
        <Modal close={close} isOpen={isOpen}>
            <h3>Smazání účtu</h3>
            <p className="h-my-15">Opravdu si přejete smazat účet? Tato akce je nevratná.</p>
            <Button onClick={deleteUser} color="alert" className="h-mb-15" loading={loading}>
                Smazat účet
            </Button>
            <br />
            <Button onClick={close} size="link">
                Zavřít
            </Button>
        </Modal>
    );
};

export default ProfileDeleteModal;
