import React, { useEffect, useState } from 'react';
import './MealPlan.scss';
import Button from '../../components/UI/Button/Button';
import MealPlanOverview from './MealPlanOverview/MealPlanOverview';
import RecipeListItem from '../../components/Recipe/RecipeListItem/RecipeListItem';
import { routesURLs } from '../../Routes';
import { getMealOverview } from '../../moduls/mealPlan';
import { MealOverviewDataType } from '../../services/types';
import MonthYear from '../../components/UI/MonthYear/MonthYear';
import useSWR, { cache } from 'swr';
import Loader from '../../components/UI/Loader/Loader';
import MealplanVideo from './MealPlayVideo/MealPlayVideo';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import useViewport from '../../hooks/useViewport';
import { ReactComponent as Chevron } from '../../assets/images/icons/ic-chevron-right.svg';
import useProfile from '../../hooks/useProfile';

const MealPlan: React.FC<RouteComponentProps> = (props) => {
    const { isCoach } = useProfile();
    const { breakpoint } = useViewport();
    const searchParams = new URLSearchParams(props.location.search);
    const [mealPlanVideo, openMealPlanVideo] = useState(!!searchParams.get('showVideo'));
    const { data } = useSWR<MealOverviewDataType>('meail-overview', getMealOverview, {
        revalidateOnMount: !cache.has('meail-overview'),
    });

    useEffect(() => {
        if (mealPlanVideo) {
            props.history.replace(routesURLs.mealPlan + '?showVideo=1');
        } else {
            props.history.replace(routesURLs.mealPlan);
        }
        // eslint-disable-next-line
    }, [mealPlanVideo]);

    if (data === undefined) return <Loader />;
    if (isCoach) return <Redirect to={routesURLs.dashboard} />;

    return (
        <>
            <div className="meal-plan dashboard-grid page-row container">
                <div className="dashboard-side-text">
                    <div className="overview-text">
                        <h1 className="h-mb-15">Tvůj stravovací plán</h1>
                        <MonthYear />
                        {breakpoint !== 'small' && (
                            <Button
                                onClick={() => openMealPlanVideo(true)}
                                icon="play-black"
                                color="blue"
                                size="small"
                                className="h-mb-1 h-mr-1 h-mx-sm-05"
                            >
                                Instruktážní video
                            </Button>
                        )}
                        {breakpoint === 'small' && (
                            <a
                                href="https://www.strongbeauty.cz/clenska-aplikace/instrukce-stravovaci-plan/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="button is-yellow is-small h-mb-1"
                            >
                                Zobrazit instrukce
                            </a>
                        )}
                        <Button to={routesURLs.mealPlanMenu} color="gray" size="small" className="h-mx-sm-05">
                            Tvůj jídelníček
                        </Button>
                        {breakpoint !== 'small' && (
                            <>
                                <p className="h-mt-2">{data?.instructions}</p>
                                <a
                                    href="https://www.strongbeauty.cz/clenska-aplikace/instrukce-stravovaci-plan/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="forward-button"
                                >
                                    Číst dál <Chevron />
                                </a>
                            </>
                        )}
                    </div>
                </div>
                <div className="dashboard-content">
                    <div className="container-row">
                        <div>
                            <MealPlanOverview
                                carbohydrates={data.carbohydrates_value}
                                fats={data.fats_value}
                                nutrients={data.nutrients_value}
                                proteins={data.proteins_value}
                            />
                        </div>
                        <div className="col-lg-6">
                            <RecipeListItem
                                title="Příděl na den"
                                icon="calendar-1-black"
                                bordered={true}
                                smallIcon={true}
                            >
                                {data.daily_allotment.map((item, index) => (
                                    <tr key={item.name + index}>
                                        <th>{item.name}</th>
                                        <th>{item.amount}</th>
                                    </tr>
                                ))}
                            </RecipeListItem>
                        </div>
                        <div className="col-lg-6">
                            <RecipeListItem
                                title="Příděl na týden"
                                icon="calendar-7-black"
                                bordered={true}
                                smallIcon={true}
                            >
                                {data.weekly_allotment.map((item, index) => (
                                    <tr key={item.name + index}>
                                        <th>{item.name}</th>
                                        <th>{item.amount}</th>
                                    </tr>
                                ))}
                            </RecipeListItem>
                        </div>
                    </div>
                    {breakpoint === 'small' && (
                        <div className="container h-text-center">
                            <Button
                                onClick={() => openMealPlanVideo(true)}
                                icon="play-black"
                                color="blue"
                                size="small"
                                className="h-mb-1 h-mr-1 h-mx-sm-05"
                            >
                                Instruktážní video
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <MealplanVideo isOpen={mealPlanVideo} close={() => openMealPlanVideo(false)} url={data.video as string} />
        </>
    );
};

export default MealPlan;
