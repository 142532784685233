import React, { useEffect, useState } from 'react';
import { classes } from '../../../services/utils';
import CircularProgress from '../../UI/CircularProgress/CircularProgress';
import './ExercisePreview.scss';
import { longBeep, shortBeep } from '../../../constants/audio';

interface ExercisePreviewPropsType {
    length: number;
    onEnded: () => void;
    playing: boolean;
    name: string;
    title: string;
    small?: boolean;
    onStart?: () => void;
}

const countDown = 10;

const ExercisePreview: React.FC<ExercisePreviewPropsType> = ({
    length,
    onEnded,
    playing,
    name,
    title,
    small = false,
    onStart,
}) => {
    const [exercisePreview, setExercisePreview] = useState(length + 1);

    useEffect(() => {
        if (onStart) onStart();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        let timeout: NodeJS.Timeout;
        if (playing) {
            setExercisePreview((num) => num - 1);
            interval = setInterval(() => {
                let number = 999999;
                setExercisePreview((num) => {
                    number = num;
                    return num - 1;
                });
                if (number <= countDown + 1 && number > 1) {
                    shortBeep.currentTime = 0;
                    shortBeep.play();
                } else if (number === 1) {
                    longBeep.currentTime = 0;
                    longBeep.play();
                }
            }, 1000);

            timeout = setTimeout(() => {
                onEnded();
            }, exercisePreview * 1000);
        }
        return () => {
            clearTimeout(interval);
            clearTimeout(timeout);
        };
        // eslint-disable-next-line
    }, [playing]);

    return (
        <div className="exercise-preview">
            <div>
                <div className={classes('exercise-preview-title', { 'is-small': small })}>{title}</div>
                <h3 className="exercise-preview-name">{name}</h3>
                <div className="exercise-preview-progress">
                    <CircularProgress total={length} value={exercisePreview} />
                </div>
            </div>
        </div>
    );
};

export default React.memo(ExercisePreview);
