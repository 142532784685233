import React from 'react';
import { classes, pad } from '../../../services/utils';
import { setupPageColors } from '../ProfileSetup';
import './Pager.scss';

interface PagerPropsType {
    items: { color: setupPageColors }[];
    activeIndex: number;
}

const Pager: React.FC<PagerPropsType> = ({ items, activeIndex }) => {
    return (
        <div className="pager">
            {items.map((item, index) => (
                <span
                    key={item.color + index}
                    className={classes(`pager-item is-${item.color}`, { 'is-active': index === activeIndex })}
                >
                    {pad(index + 1)}
                </span>
            ))}
        </div>
    );
};

export default Pager;
