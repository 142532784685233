import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useViewport from '../../../hooks/useViewport';
import { routesURLs } from '../../../Routes';
import { DishCoursesType, MealWeekRecipeType } from '../../../services/types';
import { classes } from '../../../services/utils';
import './DayMenu.scss';

interface DayMenuPropsType {
    day: string;
    dayIndex: number;
    recipes: MealWeekRecipeType[];
}

const DayMenu: React.FC<DayMenuPropsType> = ({ day, dayIndex, recipes }) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const { breakpoint } = useViewport();

    const toggleDay = () => {
        if (breakpoint === 'small') {
            setOpen(!open);
        }
    };

    const openMenuModal = (type: number) => {
        history.replace({ search: `day-menu-modal=1&day=${dayIndex ? dayIndex - 1 : 6}&type=${type}` });
    };

    return (
        <div className={classes('day-menu', { 'is-active': dayIndex === new Date().getDay(), 'is-open': open })}>
            <h3 className="day-menu-day" onClick={toggleDay}>
                {day}
            </h3>
            {recipes.map((recipe, type) => (
                <div key={recipe.id} className="day-menu-item">
                    <Link
                        to={{ pathname: routesURLs.mealPlanMenu + recipe.id, search: `type=${type}` }}
                        title={recipe.name}
                    >
                        <div
                            className="day-menu-item-image"
                            style={{ backgroundImage: `url('${recipe.image}')` }}
                        ></div>
                        <div className="day-menu-item-content">
                            <span className="h-color-medium-gray h-text-size-small">
                                {Object.values(DishCoursesType)[type]}
                            </span>
                            <h5>{recipe.name}</h5>
                        </div>
                    </Link>
                    <button
                        onClick={() => openMenuModal(type)}
                        type="button"
                        className="day-menu-item-switch"
                        title="Vyměnit za jiný recept"
                    >
                        Vyměnit za jiný recept
                    </button>
                </div>
            ))}
        </div>
    );
};

export default DayMenu;
