import React from 'react';
import { mutate } from 'swr';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import { GlobalTypes, useGlobalState } from '../../../contexts/global';
import { cancelSubscriptions } from '../../../moduls/subscriptions';
import { SubscriptionType } from '../../../services/types';

const ProfileCancelSubscriptionModal: React.FC<{
    close: () => void;
    id: number;
    isOpen: boolean;
}> = ({ close, isOpen, id }) => {
    const { state, dispatch } = useGlobalState();

    const cancelSubs = () => {
        dispatch({ type: GlobalTypes.ToggleLoader, loader: 'cancelSubscriptions' });
        cancelSubscriptions(id)
            .then(() =>
                mutate(
                    'subscriptions',
                    (subscriptions: SubscriptionType[]) => {
                        return subscriptions.map((subs) => {
                            if (subs.id === id) {
                                return { ...subs, next_payment_at: null };
                            }
                            return subs;
                        });
                    },
                    false,
                ),
            )
            .catch((error) => console.log(error.response))
            .finally(() => dispatch({ type: GlobalTypes.ToggleLoader }));
    };

    return (
        <Modal close={close} isOpen={isOpen}>
            <h3>Zrušení předplatného</h3>
            <p className="h-my-15">Opravdu si přejete zrušit toto předplatné? Tato akce je nevratná.</p>
            <Button
                onClick={cancelSubs}
                color="alert"
                className="h-mb-15"
                loading={state.loader === 'cancelSubscriptions'}
            >
                Zrušit předplatné
            </Button>
            <br />
            <Button onClick={close} size="link">
                Zavřít
            </Button>
        </Modal>
    );
};

export default ProfileCancelSubscriptionModal;
