import React from 'react';
import { classes } from '../../../services/utils';
import './TitleTable.scss';

const TitleTable: React.FC<{ header?: JSX.Element; className?: string }> = ({ header, className, children }) => {
    return (
        <div className={classes('title-table', { [className || '']: !!className })}>
            {!!header && <div className="title-table-header">{header}</div>}
            <div className="title-table-wrapper">
                <table className="title-table-table">{children}</table>
            </div>
        </div>
    );
};

export default TitleTable;
