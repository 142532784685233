import api from '../services/api';

export const getMealOverview = () => {
    return api.get('/diet-plan').then((res) => res.data.data);
};

export const getWeekMeals = () => {
    return api.get('/diet-plan/diet').then((res) => res.data.data);
};

export const getShoppingList = () => {
    return api.get('/diet-plan/diet/shopping-list').then((res) => res.data.data);
};

export const getRecipe = (_: string, id: number) => {
    return api.get(`/diet-plan/diet/recipes/${id}`).then((res) => res.data.data);
};
