import React from 'react';
import { Months } from '../../../services/types';
import Tagline from '../Tagline/Tagline';

const MonthYear = () => {
    return (
        <Tagline size="small" className="h-color-medium-gray h-mb-2">
            {`${Months[new Date().getMonth()]} ${new Date().getFullYear()}`}
        </Tagline>
    );
}

export default MonthYear
