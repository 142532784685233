import React from 'react';
import '../../Form/Inputs/FormInputText/FormInputText.scss';
import { classes } from '../../../services/utils';
import './NumberInput.scss';

interface NumberInputPropsType {
    unit?: string;
    value: string;
    color?: 'yellow' | 'pink' | 'blue' | 'purple';
    onChange: (value: string) => void;
    placeholder?: string;
    hideCheckmark?: boolean;
}

const NumberInput: React.FC<NumberInputPropsType> = ({ unit, value, onChange, placeholder, color, hideCheckmark = false }) => {
    return (
        <div className={classes('number-input-wrapper', { [`is-${color}`]: !!color, 'hide-checkmark': hideCheckmark })}>
            <div className="number-input-container">
                <input
                    type="number"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={placeholder}
                />
                {!!unit && <span className="number-input-unit">{unit}</span>}
            </div>

            <div
                className={classes('number-input-checkmark', {
                    'is-correct': !!value && +value % 1 === 0,
                    'is-incorrect': !!value && +value % 1 !== 0,
                })}
            >
                {!!value && +value % 1 !== 0 && (
                    <div className="number-input-tooltip">
                        Zadejte, prosím, <strong>celé číslo</strong>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NumberInput;
