import React from 'react';
import { pad } from '../../../services/utils';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './CircuralProgress.scss';

interface CircularProgressPropsType {
    value: number;
    total: number;
    endText?: string;
}

const CircularProgress: React.FC<CircularProgressPropsType> = ({ value, total, endText }) => {
    return (
        <div className="circular-progress-wrapper">
            <CircularProgressbar
                value={((value - 1) / total) * 100}
                text={value > 0 ? pad(value).toString() : endText || 'GO!'}
                strokeWidth={6}
                styles={buildStyles({
                    pathTransitionDuration: 1,
                    trailColor: 'transparent',
                    pathColor: '#FFF',
                    textColor: '#FFF',
                    textSize: '60%',
                })}
            />
        </div>
    );
};

export default React.memo(CircularProgress);
