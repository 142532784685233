import React, { useEffect, useState } from 'react';
import useProfile from '../../hooks/useProfile';
import './FitnessPlan.scss';
import Modal, { ModalPropsType } from '../../components/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import { setFitnessPlanFirstDay, trainingTypes, updateFitnessWeeks } from '../../moduls/trainings';
import useFitnessPlan from '../../hooks/useFitnessPlan';
import FormInputSelect from '../../components/Form/Inputs/SelectInput/FormInputSelect';
import { Formik, Form } from 'formik';
import useNotification from '../../hooks/useNotification';
import { ReactComponent as ChevronRight } from '../../assets/images/icons/ic-chevron-right-black.svg';
import { ReactComponent as ChevronLeft } from '../../assets/images/icons/ic-chevron-left-black.svg';
import FitnessPlanStone from './FitnessPlanStone';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import FitnessPlanStonePlaceholder from './FitnessPlanStonePlaceholder';
import Loader from '../../components/UI/Loader/Loader';
import { useHistory } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import useFitnessWeeks from '../../hooks/useFitnessWeeks';

let timeout: NodeJS.Timeout;

const FitnessPlan = () => {
    const [init, setInit] = useState(false);
    const [draggingId, setDraggingId] = useState<number>();
    const [instructionModal, setInstructionModal] = useState(false);
    const [conditionsModal, setConditionsModal] = useState(false);
    const [conditionsShown, setConditionsShown] = useState(false);
    const [finishProgramModal, setFinishProgramModal] = useState(false);
    const [selectDayModal, setSelectDayModal] = useState(false);
    const [activeWeek, setActiveWeek] = useState(0);
    const [weekDays] = useState([
        { title: 'PO', day: 0 },
        { title: 'ÚT', day: 1 },
        { title: 'ST', day: 2 },
        { title: 'ČT', day: 3 },
        { title: 'PÁ', day: 4 },
        { title: 'SO', day: 5 },
        { title: 'NE', day: 6 },
    ]);
    const [sortedWeekDays, setSortedWeekDays] = useState<
        {
            title: string;
            day: number;
        }[]
    >();

    const history = useHistory();
    const { data: profile } = useProfile();
    const { data: fitnessPlan, mutate: mutateFitnessPlan } = useFitnessPlan(
        profile?.fitness_plan_id as number | undefined,
    );
    const { data, mutate: mutateFitnessWeeks } = useFitnessWeeks();

    const currentWeekData = data?.[activeWeek];

    const [weekData, setWeekData] = useState<number[][]>();

    useEffect(() => {
        if (data) {
            setActiveWeek(data.length - 1);
        }
    }, [data]);

    useEffect(() => {
        setInit(true);
        if (init && !currentWeekData && !conditionsShown) {
            setConditionsModal(true);
            setConditionsShown(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeWeek]);

    useEffect(() => {
        if (history.location.search.indexOf('finish-modal=1') > -1) {
            setFinishProgramModal(true);
        }
    }, [history.location]);

    useEffect(() => {
        if (fitnessPlan?.day === null) {
            setSelectDayModal(true);
        } else {
            const week = [
                ...weekDays.slice(fitnessPlan?.day ?? 0 + 1),
                ...weekDays.slice(0, fitnessPlan?.day ?? 0 + 1),
            ];
            setSortedWeekDays(week.map((item, index) => ({ ...item, day: index })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fitnessPlan]);

    useEffect(() => {
        if (currentWeekData && sortedWeekDays?.length) {
            setWeekData(
                sortedWeekDays.map(({ day }) =>
                    currentWeekData?.fitness_trainings
                        ?.filter((training) => training.day === day)
                        .map((training) => training.id),
                ),
            );
        }
    }, [currentWeekData, sortedWeekDays]);

    useEffect(() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            const sortedData = weekData
                ?.map((dayData, index) => {
                    return dayData.map((id, i) => {
                        return { id, day: sortedWeekDays?.[index].day, slot: i + 1 };
                    });
                })
                .flat(1);
            sortedData && updateFitnessWeeks(sortedData);
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weekData]);

    const changePosition = (removeIndex: [number, number], addIndex: [number, number]) => {
        if (!weekData) return;

        let tempWeekData = [...weekData];
        const id = weekData![removeIndex[0]][removeIndex[1]];

        // remove id
        tempWeekData[removeIndex[0]] = tempWeekData[removeIndex[0]].filter((_, index) => index !== removeIndex[1]);

        // insert id
        const tempAddWeekData = [...tempWeekData[addIndex[0]]];
        tempAddWeekData.splice(addIndex[1], 0, id);
        tempWeekData[addIndex[0]] = tempAddWeekData;

        setWeekData(tempWeekData);
    };

    return (
        <>
            <div className="fitness-plan dashboard-grid page-row container">
                <div className="dashboard-side-text">
                    <div className="overview-text">
                        <h1>Tréninkový plán</h1>

                        {data && fitnessPlan && (
                            <div className="fitness-plan-week-select h-mb-3">
                                <button
                                    onClick={() => {
                                        setActiveWeek(
                                            (activeWeek + fitnessPlan?.fitness_program.weeks - 1) %
                                                fitnessPlan?.fitness_program.weeks,
                                        );
                                    }}
                                >
                                    <ChevronLeft />
                                </button>
                                <span>
                                    Týden: {activeWeek + 1}/{fitnessPlan?.fitness_program.weeks}
                                </span>
                                <button
                                    onClick={() => {
                                        setActiveWeek((activeWeek + 1) % fitnessPlan?.fitness_program.weeks);
                                    }}
                                >
                                    <ChevronRight />
                                </button>
                            </div>
                        )}

                        <div className="fitness-plan-buttons">
                            <Button onClick={() => setInstructionModal(true)}>Zobrazit instrukce</Button>

                            {fitnessPlan?.pdf && (
                                <a
                                    href={fitnessPlan.pdf}
                                    className="button is-black"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Stáhnout informativní PDF
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                <DndProvider options={HTML5toTouch}>
                    <div className="dashboard-content">
                        {fitnessPlan?.day !== null && weekData ? (
                            <div>
                                {sortedWeekDays?.map(({ day, title }, index) => (
                                    <div key={day} className="fitness-plan-item">
                                        <span className="fitness-plan-item-day">{title}</span>
                                        <div className="fitness-plan-item-container">
                                            {weekData[index].map((id, i) => {
                                                const training = currentWeekData?.fitness_trainings.find(
                                                    (training) => training.id === id,
                                                );
                                                if (!training) return null;
                                                return (
                                                    <FitnessPlanStone
                                                        id={training.id}
                                                        index={[index, i]}
                                                        key={training.id}
                                                        color={trainingTypes[training.color.toString()].color}
                                                        done={!!training.done_at}
                                                        changePosition={changePosition}
                                                        setDraggingId={setDraggingId}
                                                        stoneOpacity={draggingId === training.id ? 0 : 1}
                                                    >
                                                        {training.name}
                                                    </FitnessPlanStone>
                                                );
                                            })}
                                            <FitnessPlanStonePlaceholder
                                                index={[index, weekData[index].length]}
                                                changePosition={changePosition}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <Loader />
                        )}
                    </div>
                </DndProvider>
            </div>
            <Modal isOpen={instructionModal} close={() => setInstructionModal(false)}>
                <div
                    className="h-text-left"
                    dangerouslySetInnerHTML={{ __html: fitnessPlan?.instructions ?? '' }}
                ></div>
            </Modal>
            <Modal isOpen={conditionsModal} close={() => setConditionsModal(false)}>
                <div className="h-text-left">
                    <h4 className="h-mb-2">Nový tréninkový týden se ti zde zobrazí po splnění dvou podmínek:</h4>
                    <ol className="h-pl-1">
                        <li className="h-mb-1">
                            Všechny tréninky z předchozího týdne máš označené jako odcvičené zelenou fajfkou.
                        </li>
                        <li>Nejdříve nejbližší pondělí.</li>
                    </ol>
                </div>
            </Modal>
            <Modal
                isOpen={finishProgramModal}
                close={() => {
                    history.replace(routesURLs.fitnessPlan);
                    setFinishProgramModal(false);
                }}
            >
                <h3 className="h-mb-1">Gratulujeme!</h3>
                <p>
                    Zvládla jsi to a dokončila celý Fitness program! Jsme na tebe pyšné a máme pro tebe odměnu. Napiš
                    nám e-mail na <a href="mailto:kontakt@strongbeauty.cz">kontakt@strongbeauty.cz</a> a dostaneš od nás
                    dáreček.
                </p>
                <p className="h-my-2">
                    <strong>Jsi namotivovaná pokračovat?</strong>
                </p>
                <Button
                    onClick={() => {
                        setFinishProgramModal(false);
                        history.replace(routesURLs.fitnessPrograms);
                    }}
                >
                    Vyber si nový fitness program
                </Button>
            </Modal>
            <FitnessPlanSetDayModal
                id={fitnessPlan?.id ?? 0}
                isOpen={selectDayModal}
                close={() => setSelectDayModal(false)}
                onSuccess={() => {
                    return new Promise(async (resolve) => {
                        await mutateFitnessPlan();
                        await mutateFitnessWeeks();
                        resolve(true);
                    });
                }}
            />
        </>
    );
};

export default FitnessPlan;

const FitnessPlanSetDayModal: (props: ModalPropsType & { id: number; onSuccess: () => void }) => JSX.Element = ({
  id,
  onSuccess,
  ...props
}) => {
  const { open: openNotification } = useNotification();

  return (
    <Modal {...props} close={() => { }} hideCloseButton>
      <div className="h-pt-2 h-pb-65">
        <h3>Zvolte počáteční den</h3>
        <p>Vyberte si, kterým dnem v týdnu budeš chtít tréninkový plán začínat.</p>
        <Formik
          initialValues={{
            day: '',
          }}
          onSubmit={(values) => {
            return setFitnessPlanFirstDay(id, +values.day)
              .then(async () => {
                await onSuccess();
                props.close();
              })
              .catch((err) => {
                openNotification(err.response.data.message, 'error');
              });
          }}
          validateOnBlur={false}
        >
          {({ isSubmitting, values }) => (
            <Form className="">
              <div className="h-text-left h-mx-auto" style={{ maxWidth: '300px' }}>
                <FormInputSelect
                  name="day"
                  attributes={{
                    placeholder: 'Vyberte..',
                    hideFilter: true,
                    options: [
                      { value: '0', label: 'Pondělí' },
                      { value: '1', label: 'Úterý' },
                      { value: '2', label: 'Středa' },
                      { value: '3', label: 'Čtvrtek' },
                      { value: '4', label: 'Pátek' },
                      { value: '5', label: 'Sobota' },
                      { value: '6', label: 'Neděle' },
                    ],
                  }}
                />
              </div>
              <Button type="submit" loading={isSubmitting} className="h-mt-2" disabled={!values.day}>
                Potvrdit výběr
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
