import React, { useRef } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { Identifier } from 'dnd-core';

interface DragItem {
    index: [number, number];
    type: string;
}

const FitnessPlanStonePlaceholder: React.FC<{
    index: [number, number];
    changePosition: (removeIndex: [number, number], addIndex: [number, number]) => void;
}> = ({ index, changePosition }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId, isDragging }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null; isDragging: boolean }
    >({
        accept: 'stone',
        collect(monitor: DropTargetMonitor<DragItem, void>) {
            return {
                handlerId: monitor.getHandlerId(),
                isDragging: monitor.canDrop(),
            };
        },
        hover(item: DragItem, monitor: DropTargetMonitor<DragItem, void>) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // // Don't replace items with themselves
            if (dragIndex[0] === hoverIndex[0]) {
                return;
            }

            // // Time to actually perform the action
            changePosition(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });

    drop(ref);

    return (
        <div
            ref={ref}
            className={['fitness-plan-stone-placeholder', isDragging ? 'is-dragging' : ''].filter(Boolean).join(' ')}
            data-handler-id={handlerId}
        ></div>
    );
};

export default FitnessPlanStonePlaceholder;
