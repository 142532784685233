import React, { useReducer, Dispatch, useMemo } from 'react';
import { NotificationStyle } from '../components/UI/Notification/Notification';

interface State {
    loader: string;
    notification: {
        text: string;
        style?: NotificationStyle;
    };
}

enum GlobalTypes {
    ToggleLoader = 'TOGGLE_LOADER',
    ToggleNotification = 'TOGGLE_NOTIFICATION',
}

type Actions =
    | { type: GlobalTypes.ToggleLoader; loader?: string }
    | { type: GlobalTypes.ToggleNotification; text?: string; style?: NotificationStyle };

const initialState = {
    loader: '',
    notification: {
        text: '',
        style: 'error' as NotificationStyle,
    },
};

function reducer(state: State, action: Actions) {
    switch (action.type) {
        case GlobalTypes.ToggleLoader:
            return { ...state, loader: action.loader || '' };
        case GlobalTypes.ToggleNotification:
            return {
                ...state,
                notification: {
                    text: action.text || '',
                    style: action.style || state.notification.style,
                },
            };
        default:
            throw new Error('This action type is not exist in Global context');
    }
}

const GlobalProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = useMemo(() => ({ state, dispatch }), [state]);

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const AppContext = React.createContext<{
    state: State;
    dispatch: Dispatch<Actions>;
}>({
    state: initialState,
    dispatch: () => null,
});

function useGlobalState() {
    const context = React.useContext(AppContext);
    if (context === undefined) {
        throw new Error('useGlobalState must be used within a GlobalProvider');
    }
    return context;
}

export { GlobalProvider, useGlobalState, GlobalTypes };
