import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './services/polyfills';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './App';
import { GlobalProvider } from './contexts/global';
import { ViewportProvider } from './hooks/useViewport';
import { SWRConfig } from 'swr';
import * as Sentry from '@sentry/react';

Sentry.init({
    dsn: 'https://f1e0152c3714e4b9b3f8be4b2058d65b@o4507222270345216.ingest.de.sentry.io/4507323399929936',
    integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()],
    sampleRate: 0.2,
    tracesSampleRate: 0.2,
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,
    environment: process.env.APP_ENV,
    enabled: Boolean(process.env.APP_ENV),
});

const Providers: React.FC = ({ children }) => {
    return (
        <ViewportProvider>
            <GlobalProvider>{children}</GlobalProvider>
        </ViewportProvider>
    );
};

const swrOptions = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    errorRetryCount: 1,
};

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Providers>
                <SWRConfig value={swrOptions}>
                    <App />
                </SWRConfig>
            </Providers>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);
