import React from 'react';
import { Link } from 'react-router-dom';
import { routesURLs } from '../../../Routes';
import './AuthWrapper.scss';
import logo from '../../../assets/images/brands/logo-gray-horizontal.png';
import { classes } from '../../../services/utils';

interface AuthWrapperPropsType {
    tagline?: string;
    controls?: JSX.Element;
    color?: 'yellow' | 'pink' | 'blue' | 'purple';
    coach?: 'katy' | 'domi' | 'marti' | 'reni';
    illustrationCover?: 'is-login-illustration';
    className?: string;
}

const AuthWrapper: React.FC<AuthWrapperPropsType> = ({
    tagline,
    controls,
    color = 'yellow',
    coach,
    children,
    illustrationCover,
    className,
}) => {
    return (
        <div className={classes('auth-wrapper', { [className || '']: !!className })}>
            <div className="auth-wrapper-content">
                <div className="auth-wrapper-topbar container-row center-md">
                    <div className="col-md-8">
                        <Link to={routesURLs.dashboard} className="auth-wrapper-logo">
                            <img src={logo} alt="Strong beauty" />
                        </Link>
                        {!!tagline && <div className="auth-wrapper-tagline">{tagline}</div>}
                    </div>
                </div>
                <div className="auth-wrapper-main container-row center-md">
                    <div className="col-md-8">{children}</div>
                </div>
                <div className="container-row center-md">
                    <div className="col-md-8">{controls}</div>
                </div>
            </div>
            <div
                className={classes(`auth-wrapper-illustration is-${color}`, {
                    [illustrationCover || '']: !!illustrationCover,
                })}
                coach-image={coach}
            ></div>
        </div>
    );
};

export default AuthWrapper;
