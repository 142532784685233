import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Link, RouteComponentProps } from 'react-router-dom';
import AuthWrapper from '../../components/Layout/AuthWrapper/AuthWrapper';
import Button from '../../components/UI/Button/Button';
import './Login.scss';
import { LoginFormTypes } from '../../components/Form/form-types';
import FormInputText from '../../components/Form/Inputs/FormInputText/FormInputText';
import { transformErrors } from '../../services/utils';
import { login } from '../../moduls/auth';
import { routesURLs } from '../../Routes';

const initialValues = {
    email: '',
    password: '',
};

const attributes = {
    email: {
        placeholder: 'E-mail',
        label: null,
    },
    password: {
        placeholder: 'Heslo',
        label: null,
        password: true,
    },
};

const Login: React.FC<RouteComponentProps> = (props) => {
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Nevalidní e-mail').required('Povinné pole'),
        // eslint-disable-next-line
        password: Yup.string().min(6, 'Minimální počet znaků je ${min}').required('Povinné pole'),
    });

    const onSubmit = async (values: LoginFormTypes, { resetForm, setErrors }: FormikHelpers<LoginFormTypes>) => {
        return login(values)
            .then(async (profile) => {
                resetForm();
                if (profile.user_questionnaire) {
                    props.history.replace(routesURLs.dashboard);
                } else {
                    props.history.replace(routesURLs.profileSetup);
                }
            })
            .catch((error) => setErrors(transformErrors(error)));
    };

    return (
        <AuthWrapper tagline="Přihlášení">
            <div className="login-content">
                <h1>Přihlásit se</h1>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    // validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <FormInputText name="email" attributes={attributes.email} />
                            <FormInputText name="password" attributes={attributes.password} />
                            <div className="login-controls">
                                <Link to={routesURLs.passwordRecovery} className="button is-link">
                                    Zapomenuté heslo
                                </Link>
                                <Button type="submit" loading={isSubmitting}>
                                    Přihlásit se
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </AuthWrapper>
    );
};

export default Login;
