import React from 'react';
import './ForwardButton.scss';
import { Link } from 'react-router-dom';
import { classes } from '../../../services/utils';
import { ReactComponent as Chevron } from '../../../assets/images/icons/ic-chevron-right.svg';

const ForwardButton: React.FC<{ to: string; className?: string }> = ({ to, className, children }) => {
    return (
        <Link to={to} className={classes('forward-button', { [className || '']: !!className })}>
            {children} <Chevron />
        </Link>
    );
};

export default ForwardButton;
