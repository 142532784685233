import useSWR, { cache } from 'swr';
import { getFitnessPrograms } from '../moduls/trainings';

export type FitnessProgram = {
    description: string;
    id: number;
    image: string;
    name: string;
    weeks: number;
};

const useFitnessPrograms = () => {
    const data = useSWR<FitnessProgram[]>('fitness-programs', getFitnessPrograms, {
        revalidateOnMount: !cache.has('fitness-programs'),
    });
    return data;
};

export default useFitnessPrograms;
