import useSWR, { cache } from 'swr';
import { getTranslations } from '../moduls/global';

const useTranslations = () => {
    const data = useSWR<{ id: number; content: string }[]>('translations', getTranslations, {
        revalidateOnMount: !cache.has('translations'),
    });

    return {
        ...data,
        data: data.data?.reduce((rest, curr) => ({ ...rest, [curr.id]: curr.content }), {}) as Record<number, string>,
    };
};

export default useTranslations;
