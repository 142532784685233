import { endOfWeek, format, startOfWeek } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router-dom';
import BackButton from '../../components/UI/Button/BackButton';
import Button from '../../components/UI/Button/Button';
import Loader from '../../components/UI/Loader/Loader';
import Tagline from '../../components/UI/Tagline/Tagline';
import useViewport from '../../hooks/useViewport';
import useWeekMeals from '../../hooks/useWeekMeals';
import { routesURLs } from '../../Routes';
import DayMenu from './DayMenu/DayMenu';
import DayMenuHead from './DayMenu/DayMenuHead';
import DayMenuModal from './DayMenuModal/DayMenuModal';
import './MealPlanMenu.scss';

const MealPlanMenu = () => {
    const history = useHistory();
    const { breakpoint } = useViewport();
    const { data } = useWeekMeals();
    const openMenuModal = new URLSearchParams(history.location.search).get('day-menu-modal');

    if (data === undefined) return <Loader />;

    return (
        <div className="meal-plan-menu page-row container">
            <div className="meal-plan-menu-header">
                <BackButton to={routesURLs.mealPlan} />
                {breakpoint === 'small' && (
                    <div className="h-text-center">
                        <h2 className="h-mb-1 h-mt-15 h-width-100">Tvůj jídelníček</h2>
                        <Tagline size="small" className="h-color-medium-gray h-mb-2">
                            <DatePariod />
                        </Tagline>
                    </div>
                )}
                <div className="meal-plan-menu-header-controls">
                    {breakpoint !== 'small' && (
                        <Tagline size="small" className="h-color-medium-gray h-mr-1">
                            <DatePariod />
                        </Tagline>
                    )}
                    <Button
                        to={routesURLs.mealPlanShoppingList}
                        color="blue"
                        icon="list-black"
                        size="small"
                        className="h-mr-1 h-mr-sm-0"
                    >
                        Nákupní seznam
                    </Button>
                    {/* {breakpoint !== 'small' && <DownloadMenu file={data.diet_file!} />} */}
                </div>
            </div>
            <div className="meal-plan-menu-wrapper">
                <DayMenuHead />
                <DayMenu recipes={data.selected_recipes.map((recipe) => recipe[0])} day="Pondělí" dayIndex={1} />
                <DayMenu recipes={data.selected_recipes.map((recipe) => recipe[1])} day="Úterý" dayIndex={2} />
                <DayMenu recipes={data.selected_recipes.map((recipe) => recipe[2])} day="Středa" dayIndex={3} />
                <DayMenu recipes={data.selected_recipes.map((recipe) => recipe[3])} day="Čtvrtek" dayIndex={4} />
                <DayMenu recipes={data.selected_recipes.map((recipe) => recipe[4])} day="Pátek" dayIndex={5} />
                <DayMenu recipes={data.selected_recipes.map((recipe) => recipe[5])} day="Sobota" dayIndex={6} />
                <DayMenu recipes={data.selected_recipes.map((recipe) => recipe[6])} day="Neděle" dayIndex={0} />
            </div>
            <DayMenuModal
                isOpen={!!openMenuModal}
                close={() => {
                    history.replace({ search: '' });
                }}
            />
            {/* <div className="h-text-center h-mt-1">
                {breakpoint === 'small' && <DownloadMenu file={data.diet_file!} />}
            </div> */}
        </div>
    );
};

const DatePariod = () => {
    return (
        <>
            {`NA OBDOBÍ ${format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'dd. MM.')} - ${format(
                endOfWeek(new Date(), { weekStartsOn: 1 }),
                'dd. MM.',
            )}`}
        </>
    );
};

export default MealPlanMenu;
